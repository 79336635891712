// @flow
import * as actions from '../../actions/industryDashboards';

type State = {
  isFetching: boolean,
  results: Array<any>,
  error: error,
};

const initialState = {
  isFetching: false,
  results: [],
  error: undefined,
};

const filterList = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actions.LOAD_FILTER_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.LOAD_FILTER_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        results: action.payload,
      };
    case actions.LOAD_FILTER_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};

export default filterList;
