// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';

export const GET_CATEGORIES_CURRENT_DATA_REQUEST =
  'GET_CATEGORIES_CURRENT_DATA_REQUEST';
export const GET_CATEGORIES_CURRENT_DATA_SUCCESS =
  'GET_CATEGORIES_CURRENT_DATA_SUCCESS';
export const GET_CATEGORIES_CURRENT_DATA_FAILURE =
  'GET_CATEGORIES_CURRENT_DATA_FAILURE';

const getCategoriesCurrentDataRequest = (): Action => {
  return {
    type: GET_CATEGORIES_CURRENT_DATA_REQUEST,
  };
};

const getCategoriesCurrentDataSuccess = (data): Action => {
  return {
    type: GET_CATEGORIES_CURRENT_DATA_SUCCESS,
    payload: data,
  };
};

const getCategoriesCurrentDataFailure = (accessInfo): Action => {
  return {
    type: GET_CATEGORIES_CURRENT_DATA_FAILURE,
    accessInfo,
  };
};

const PERIOD_TYPE = 2;

export const getCategoriesCurrentData = (
  dashboardId: number
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(getCategoriesCurrentDataRequest());

    const token = getAuthToken();
    const currentState = getState();
    const organisationId = currentState.auth.organisations.currentOrganisation;
    const teamId = currentState.auth.teams.currentTeam;
    const {
      metric,
      currentSegment,
      currentCategory,
    } = currentState.industryDashboards.categoriesPage;
    const { currentFilter } = currentState.industryDashboards.filters;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/industry/${dashboardId}/charts/categories/current?metric=${metric}&periodType=${PERIOD_TYPE}&filterBreak=${currentFilter}&segment=${currentSegment}&category=${currentCategory}`;

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getCategoriesCurrentDataSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getCategoriesCurrentDataFailure(error));
    return error;
  }
};
