// @flow
import { combineReducers } from 'redux';

import consumerConfidence from './consumerConfidence';
import governmentConfidence from './governmentConfidence.js';
import businessConfidence from './businessConfidence.js';
import inflationExpectations from './inflationExpectations.js';
import unemployment from './unemployment.js';
import underEmployment from './underEmployment';

const metrics = combineReducers<_, { +type: string }>({
  consumerConfidence,
  businessConfidence,
  governmentConfidence,
  inflationExpectations,
  unemployment,
  underEmployment,
});

export default metrics;
