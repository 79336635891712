// @flow
import * as actions from '../../actions/industryDashboards';
import type { SegmentListType } from '../../types/industry';

type State = {
  isFetching: boolean,
  options: SegmentListType,
  error: error,
};

const initialState = {
  isFetching: false,
  options: [],
  error: undefined,
};

const players = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actions.LOAD_PLAYERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.LOAD_PLAYERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        options: action.payload,
      };
    case actions.LOAD_PLAYERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};

export default players;
