// @flow
import * as actions from '../../../actions';

const DEFAULT_FILTER = 1;

const exampleData = [
  {
    id: 1,
    name: 'All - State',
    order: 1,
  },
  {
    id: 2,
    name: 'New South Wales',
    order: 2,
  },
  {
    id: 3,
    name: 'Victoria',
    order: 3,
  },
  {
    id: 4,
    name: 'Queensland',
    order: 4,
  },
  {
    id: 5,
    name: 'ACT',
    order: 5,
  },
  {
    id: 6,
    name: 'Western Australia',
    order: 6,
  },
  {
    id: 7,
    name: 'South Australia',
    order: 7,
  },
  {
    id: 8,
    name: 'Tasmania',
    order: 8,
  },
  {
    id: 9,
    name: 'NT',
    order: 9,
  },
];

type State = {
  isFetching: boolean,
  results: Array<any>,
  error: error,
  currentFilter: number,
};

const initialState = {
  isFetching: false,
  results: exampleData,
  error: undefined,
  currentFilter: DEFAULT_FILTER,
};

const state = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actions.LOAD_RECOVERY_STATE_FILTERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.LOAD_RECOVERY_STATE_FILTERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        results: action.payload,
      };
    case actions.LOAD_RECOVERY_STATE_FILTERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    case actions.SET_RECOVERY_STATE_FILTER:
      return {
        ...state,
        currentFilter: action.payload,
      };
    default:
      return state;
  }
};

export default state;
