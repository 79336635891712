// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { switchTo } from 'roy-morgan-auth';
import { teamChange, organisationChange } from '../actions';
import liveLogo from '../assets/images/logos/rmr_live_logo_v2.png';

import type {
  User,
  Organisation,
  Team,
  Products
} from 'roy-morgan-auth';

// the abstracted managed menu with data from the CMS
import ManagedMenu from '../components/ManagedMenu/ManagedMenu';

type Props = {
  authUser?: User,
  organisations: Organisation[],
  teams: Team[],
  currentOrganisation: number,
  currentTeam: number,
  organisationChange: (organisationId: number) => Promise<void>,
  teamChange: (teamId: number) => Promise<void>,
  logOut: () => Promise<*>,
  communities: any[],
  loadCommunitiesAndPersonas: () => Promise<*>,
  location: {
    pathname: string,
  },
  history: {
    push: (p: string) => void,
  },
  products: Products,
  switchTo: (
    email: string,
    switchFromEmail: string,
    switchFromToken: string
  ) => Promise<void>,
  isInternalUser: boolean,
  loggedIn: boolean,
};

class Header extends React.Component<Props> {
  render() {
    const {
      authUser,
      location,
      history,
      organisations,
      teams,
      currentOrganisation,
      currentTeam,
      organisationChange,
      teamChange,
      products,
      switchTo,
      isInternalUser,
      loggedIn
    } = this.props;

    const { pathname } = location;

    const loginPage = pathname === '/login' || pathname === '/';
    // const loginPage = true;
    let showLogin = true;
    // Don't show the header for logged in users on visit vic page
    const visitVicClass = pathname.toLowerCase().includes('visitvic') && loggedIn && 'visitvic-header';
    // Don't show login button when login form is present.
    // This is present when there is no authUser.
    if (authUser || loginPage) {
      showLogin = false;
    }
    return (
      <div
        className={`header-area ${visitVicClass} ${
          authUser ? 'header-area--logged-in' : 'header-area--logged-out'
        }`}
      >
        <ManagedMenu
          logoUrl={liveLogo}
          authUser={authUser}
          publicNav
          pathname={location.pathname}
          showLogin={showLogin}
          organisations={organisations}
          currentOrganisation={currentOrganisation}
          teams={teams}
          currentTeam={currentTeam}
          organisationChange={organisationChange}
          teamChange={teamChange}
          products={products}
          switchTo={switchTo}
          location={location}
          history={history}
          mobileBreakpoint={900}
          userMenuBreakpoint={1000}
          isInternalUser={isInternalUser}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authUser: state.auth.user.user,
    organisations: Object.keys(state.auth.organisations.byId).map(
      i => state.auth.organisations.byId[i]
    ),
    teams: Object.keys(state.auth.teams.byId).map(
      i => state.auth.teams.byId[i]
    ),
    currentOrganisation: state.auth.organisations.currentOrganisation,
    currentTeam: state.auth.teams.currentTeam,
    products: state.auth.products,
    isInternalUser:
      state.auth &&
      state.auth.user &&
      state.auth.user.user &&
      state.auth.user.user.isInternalUser,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchTo,
      teamChange,
      organisationChange,
    },
    dispatch
  );

export default connect<Props, {}, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(Header);
