// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../../helpers';

export const GET_STATE_CHART_DATA_REQUEST = 'GET_STATE_CHART_DATA_REQUEST';
export const GET_STATE_CHART_DATA_SUCCESS = 'GET_STATE_CHART_DATA_SUCCESS';
export const GET_STATE_CHART_DATA_FAILURE = 'GET_STATE_CHART_DATA_FAILURE';

const getStateChartDataRequest = (): Action => {
  return {
    type: GET_STATE_CHART_DATA_REQUEST,
  };
};

const getStateChartDataSuccess = (data): Action => {
  return {
    type: GET_STATE_CHART_DATA_SUCCESS,
    payload: data,
  };
};

const getStateChartDataFailure = (accessInfo): Action => {
  return {
    type: GET_STATE_CHART_DATA_FAILURE,
    accessInfo,
  };
};

export const getStateChartData = (
  dashboardId: number,
  isDemo: boolean
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(getStateChartDataRequest());

    const token = getAuthToken();
    const organisationId = getState().auth.organisations.currentOrganisation;
    const teamId = getState().auth.teams.currentTeam;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/`;

    if (isDemo) {
      targetUrl += '/risk-demo/charts/state';
    } else {
      targetUrl += `risk/${dashboardId}/charts/state`;
    }

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getStateChartDataSuccess(camelizeKeys(response, noCamelize)));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getStateChartDataFailure(error));
    return error;
  }
};
