// @flow
import * as Sentry from '@sentry/browser';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';

import type { Action, ThunkAction, Dispatch } from '../../../types';

export const LOAD_METRIC_FILTERS_REQUEST = 'LOAD_METRIC_FILTERS_REQUEST';
export const LOAD_METRIC_FILTERS_SUCCESS = 'LOAD_METRIC_FILTERS_SUCCESS';
export const LOAD_METRIC_FILTERS_FAILURE = 'LOAD_METRIC_FILTERS_FAILURE';

const loadMetricFiltersRequest = (): Action => ({
  type: LOAD_METRIC_FILTERS_REQUEST,
});

const loadMetricFiltersSuccess = (filters): Action => ({
  type: LOAD_METRIC_FILTERS_SUCCESS,
  payload: filters,
});

const loadMetricFiltersFailure = (error): Action => ({
  type: LOAD_METRIC_FILTERS_FAILURE,
  error,
});

export const loadMetricFilters = (): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadMetricFiltersRequest());

    const currentState = getState();
    const token = getAuthToken();

    const headers = {
      token,
      'context-organisation':
        currentState.auth.organisations.currentOrganisation,
      'context-team': currentState.auth.teams.currentTeam,
    };
    //TODO: UPDATE ENDPOINT, ONLY HERE TO SCAFFOLD ACTION
    const baseUrl = LIVE_API_BASE_URL;
    let targetUrl = `${baseUrl}/reports/universes`;

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      headers,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(loadMetricFiltersSuccess(response));
  } catch (error) {
    dispatch(loadMetricFiltersFailure(error));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
