// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';

export const CREATE_DASHBOARD_REQUEST =
  'CREATE_DASHBOARD_REQUEST';
export const CREATE_DASHBOARD_SUCCESS =
  'CREATE_DASHBOARD_SUCCESS';
export const CREATE_DASHBOARD_FAILURE =
  'CREATE_DASHBOARD_FAILURE';

const createDashboardRequest = (): Action => {
  return {
    type: CREATE_DASHBOARD_REQUEST,
  };
};

const createDashboardSuccess = (dashboard): Action => {
  return {
    type: CREATE_DASHBOARD_SUCCESS,
    payload: dashboard,
  };
};

const createDashboardFailure = (error): Action => {
  return {
    type: CREATE_DASHBOARD_FAILURE,
    error,
  };
};

export const createDashboard = (dashboard: any): ThunkAction => async (
  dispatch: Dispatch
) => {
  try {
    refreshToken(dispatch);
    dispatch(createDashboardRequest());

    const token = getAuthToken();

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/management/dashboards`;

    const headers = {
      token,
      'Content-Type': 'application/json',
    };

    const body = dashboard;

    const response = await callAPI({
      method: 'POST',
      targetUrl,
      undefined,
      headers,
      body
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(createDashboardSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(createDashboardFailure(error));
    return error;
  }
};
