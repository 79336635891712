export const universesById = {
  1: 'All',
  2: 'New South Wales',
  3: 'Victoria',
  4: 'Queensland',
  5: 'South Australia',
  6: 'Western Australia',
};

export const segmentsById = {
  all: 'All',
  supermarkettotal: 'Supermarket Total',
  supermarketfresh: 'Supermarket Fresh',
  nonsupermarketfresh: 'Non Supermarket Fresh',
};

export const categoriesById = {
  1: 'All',
  2: 'Fresh Meat',
  3: 'Fresh Seafood',
  4: 'Fresh Fruit/Veg',
  5: 'Fresh Bread',
  6: 'Fresh Deli',
};
