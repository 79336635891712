export const brandColours = {
  Woolworths: '#00b140',
  Coles: '#e03e52',
  Aldi: '#004b87',
  IGA: '#ff7f41',
  'Other Supermarkets': '#000000',
  'Other Supermarket': '#000000',
  //Unsure of this colour!
  other: '#568a9e',
  'Other Non-supermarket': '#568a9e',
  'Non-Supermarket': '#568a9e',
  Butcher: '#00b398',
  Market: '#bbbcbc',
  Delicatessen: '#00b0f0',
  'Green Grocer': '#00b0f0',
  'Seafood Retailer': '#796e65',
  'Total Fresh': '#568a9e',
  'Supermarket Fresh': '#00b0f0',
  'Non Supermarket Fresh': '#bbbcbc',
  "Brumby's Bakery": '#bbbcbc',
  'Other Bread Shop': '#00b0f0',
  'Fresh Meat': '#568a9e',
  'Fresh Bread': '#00b398',
  'Fresh Deli': '#004b87',
  'Fresh Fruit/Veg': '#00aec7',
  'Fresh Seafood': '#bbbcbc',
};

export const visitVicLocationToColour = {
  // By City
  Melbourne: '#201547',
  Sydney: '#4982cf',
  Brisbane: '#af272f',
  Adelaide: '#00b7bd',
  Perth: '#53565a',
  Hobart: '#E3521B',
  Darwin: '#3ef8ff',
  Canberra: '#5739c0',
  'Gold Coast': '#006B71',

  // By State
  VIC: '#201547',
  'NSW/ACT': '#4982cf',
  QLD: '#af272f',
  SA: '#00b7bd',
  WA: '#53565a',
  TAS: '#E3521B',
  NT: '#3ef8ff',
  ACT: '#5739c0',

  "Can't Say": '#a898e0',
};

export const visitVicIndexToColour = [
  '#201547',
  '#4982cf',
  '#af272f',
  '#00b7bd',
  '#53565a',
  '#000000',
  '#3ef8ff',
  '#5739c0',
  '#006B71',
  '#a898e0',
];
