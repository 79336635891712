// @flow
import * as actions from '../../../actions';

type State = {
  value: number,
  previousValue: number,
  isFetching: boolean,
  error: ?{},
};

const initialState = {
  id: 2,
  name: 'Business Confidence',
  value: 0,
  previousValue: 0,
  isFetching: false,
  error: undefined,
  updatePeriod: 'Monthly',
  valueType: 'number',
};

const businessConfidence = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_BUSINESS_CONFIDENCE_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.GET_BUSINESS_CONFIDENCE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        value: action.payload.value,
        previousValue: action.payload.previousValue,
      };
    case actions.GET_BUSINESS_CONFIDENCE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};

export default businessConfidence;
