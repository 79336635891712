// @flow
import type { Action } from '../../types';

export const SET_SEGMENTS_METRIC = 'SET_SEGMENTS_METRIC';

export const setSegmentsMetric = (value): Action => {
  return {
    type: SET_SEGMENTS_METRIC,
    payload: value,
  };
};
