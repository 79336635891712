// @flow
// import * as actions from '../../../actions';

const DEFAULT_FILTER = 2;


const exampleData = [
  {
    "id": 2,
    "name": "Melbourne",
    "regionTypeId": 2,
    "regionType": "City"
  } 
];

type State = {
  isFetching: boolean,
  results: Array<any>,
  error: error,
  currentFilter: number,
};

const initialState = {
  isFetching: false,
  results: exampleData,
  error: undefined,
  currentFilter: DEFAULT_FILTER,
};

const emotionalRegion = (state: State = initialState, action: Any) => {
  switch (action.type) {
    // case actions.LOAD_REGION_FILTERS_SUCCESS:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     results: action.payload,
    //   }; 
    // case actions.SET_REGION_FILTER:
    //   return {
    //     ...state,
    //     currentFilter: action.payload,
    //   };
    default:
      return state;
    }
};

export default emotionalRegion;
 
