export const trustDat = [
  {
    "period": "Jan 19",
    "value": 4.2
  },
  {
    "period": "Feb 19",
    "value": 3.6
  },
  {
    "period": "Mar 19",
    "value": 3.2
  },
  {
    "period": "Apr 19",
    "value": 2.9
  },
  {
    "period": "May 19",
    "value": 3.1
  },
  {
    "period": "Jun 19",
    "value": 2.6
  },
  {
    "period": "Jul 19",
    "value": 2.2
  },
  {
    "period": "Aug 19",
    "value": 2.3
  },
  {
    "period": "Sep 19",
    "value": 1.7
  },
  {
    "period": "Oct 19",
    "value": 1.1
  },
  {
    "period": "Nov 19",
    "value": 0.3
  },
  {
    "period": "Dec 19",
    "value": -0.8
  },
  {
    "period": "Jan 20",
    "value": -1.6
  }
]