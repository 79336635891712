// @flow
import type { MessagesState } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

const initialState: MessagesState = {
  content: undefined,
  type: undefined,
};

/* Reducer */
export const messages = (
  state: MessagesState = initialState,
  action: Object
) => {
  switch (action.type) {
    case 'USER_UPDATE_FAILURE':
    case 'USER_UPDATE_PASSWORD_FAILURE':
      return {
        content: action.error,
        type: 'error',
      };
    case 'UPDATE_ORGANISATION_DASHBOARD_ACCESS_SUCCESS':
      return {
        content: `Dashboard Access Level Updated for ${action.payload.name}`,
        type: 'success',
      };
    case 'UPDATE_ORGANISATION_DASHBOARD_ACCESS_FAILURE':
      return {
        content: action.error.msg,
        type: 'error',
      };
    case 'USER_UPDATE_SUCCESS':
      return {
        content: 'User details successfully updated',
        type: 'success',
      };
    case 'USER_UPDATE_PASSWORD_SUCCESS':
      return {
        content: 'User password updated successfully',
      };
    case LOG_OUT_SUCCESS:
      return {
        content: 'Logged out',
        type: 'warning',
      };
    case 'MESSAGES_RESET':
      return initialState;
    default:
      return state;
  }
};
