// @flow
import * as actions from '../../../actions';

const DEFAULT_FILTER = 'bar';

const exampleData = [
  { id: 'bar', name: 'Bar Chart' },
  { id: 'radar', name: 'Spider Chart' },
];

type State = {
  options: Array<any>,
  currentFilter: number,
};

const initialState = {
  options: exampleData,
  currentFilter: DEFAULT_FILTER,
};

const chartView = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actions.SET_PP_CHART_VIEW:
      return {
        ...state,
        currentFilter: action.payload,
      };
    default:
      return state;
  }
};

export default chartView;
