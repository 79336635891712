// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../types';
import { callAPI, LIVE_API_BASE_URL } from '../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';

export const GET_DASHBOARD_ACCESS_LEVEL_REQUEST =
  'GET_DASHBOARD_ACCESS_LEVEL_REQUEST';
export const GET_DASHBOARD_ACCESS_LEVEL_SUCCESS =
  'GET_DASHBOARD_ACCESS_LEVEL_SUCCESS';
export const GET_DASHBOARD_ACCESS_LEVEL_FAILURE =
  'GET_DASHBOARD_ACCESS_LEVEL_FAILURE';
export const RESET_DASHBOARD_ACCESS = 'RESET_DASHBOARD_ACCESS';

const getDashboardAccessLevelRequest = (): Action => {
  return {
    type: GET_DASHBOARD_ACCESS_LEVEL_REQUEST,
  };
};

const getDashboardAccessLevelSuccess = (value): Action => {
  return {
    type: GET_DASHBOARD_ACCESS_LEVEL_SUCCESS,
    payload: value,
  };
};

const getDashboardAccessLevelFailure = (accessInfo): Action => {
  return {
    type: GET_DASHBOARD_ACCESS_LEVEL_FAILURE,
    accessInfo,
  };
};

export const resetDashboardAccess = (): Action => {
  return {
    type: RESET_DASHBOARD_ACCESS,
  };
};

export const getDashboardAccessLevel = (
  dashboardId: number
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(getDashboardAccessLevelRequest());

    const token = getAuthToken();
    const organisationId = getState().auth.organisations.currentOrganisation;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/dashboards/${dashboardId}/access`;

    const headers = {
      token,
      'context-organisation': organisationId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(
      getDashboardAccessLevelSuccess(camelizeKeys(response, noCamelize))
    );

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getDashboardAccessLevelFailure(error));
    return error;
  }
};
