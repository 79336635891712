// @flow
import * as actions from '../../actions/riskMonitor';

type State = {
  value: number,
  previousValue: number,
  isFetching: boolean,
  error: ?{},
};

const initialState = {
  value: 0,
  previousValue: 0,
  isFetching: false,
  error: undefined,
};

export const industryAverage = (
  state: State = initialState,
  action: Object
) => {
  switch (action.type) {
    case actions.GET_INDUSTRY_AVERAGE_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.GET_INDUSTRY_AVERAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        value: action.payload.value,
        previousValue: action.payload.previousValue,
      };
    case actions.GET_INDUSTRY_AVERAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};
