// @flow
import * as actions from '../../actions/riskMonitor';

type State = {
  data: Array<{
    state: string,
    value: number,
  }>,
  isFetching: boolean,
  error: ?{},
};

const initialState = {
  data: [],
  isFetching: false,
  error: undefined,
};

export const stateChart = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_STATE_CHART_DATA_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.GET_STATE_CHART_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        data: action.payload,
      };
    case actions.GET_STATE_CHART_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};
