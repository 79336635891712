import { dashboardTypeById } from './index';

function createSubRoute(dashboardTypeId) {
  switch (dashboardTypeId) {
    case 2:
      return '/overview';
    case 3:
      return '/image-perceptions';
    default:
      return '';
  }
}

function getLiveDomain() {
  switch(process.env.REACT_APP_RM_ENV) {
    case 'prod':
      return 'roymorgan.com';
    case 'uat':
      return 'roymorganuat.com';
    default:
      return 'roymorgandev.com';
  }
}

function createRouteForCustomDashboard(dashboard) {
  const liveDomain = getLiveDomain();
  return `//${dashboard.customSubdomain}.${liveDomain}`;
}

function createRouteForLiveReportsDashboard(dashboard) {
  const dashboardType = dashboardTypeById[dashboard.dashboardTypeId];
  const subRoute = createSubRoute(dashboard.dashboardTypeId);
  return `/reports/${dashboardType}/${dashboard.id}${subRoute}`;
}

export function createDashBoardRoute(dashboard) {
  return dashboard.dashboardTypeId === 4  // custom type
    ? createRouteForCustomDashboard(dashboard) 
    : createRouteForLiveReportsDashboard(dashboard);
}