// @flow
import { combineReducers } from 'redux';
import barChart from './barChart';
import radarChart from './radarChart';
import lineChart from './lineChart';
import chartView from './chartView';

const emotional = combineReducers<_, { +type: string }>({
  barChart,
  radarChart,
  lineChart,
  chartView,
});

export default emotional;
