// @flow
import { combineReducers } from 'redux';

import imageView from './imageView';
import emotionalView from './emotionalView';
import attribute from './attribute';
import region from './region';
import emotionalRegion from './emotionalRegion';
import personalityView from './personalityView';
import personalityDetailedView from './personalityDetailedView';
import personalityStatement from './personalityStatement';

const filters = combineReducers<_, { +type: string }>({
  imageView,
  emotionalView,
  attribute,
  region,
  emotionalRegion,
  personalityView,
  personalityDetailedView,
  personalityStatement,
});

export default filters;
