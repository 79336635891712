// @flow
import * as actions from '../../actions/';

type State = {
  data: Array<{
    period: string,
    [key: number]: number,
  }>,
  isFetching: boolean,
  error: {},
};

const dataExample = [
  {
    period: 'MAR 19',
    'Consumer Confidence': 88.8,
    'Business Confidence': 117.8,
    'Government Confidence': 98.8,
    'Inflation Expectations': 0.028,
    Unemployment: 0.068,
    'Under-Employment': 0.088,
  },
  {
    period: 'APR 19',
    'Consumer Confidence': 88.2,
    'Business Confidence': 117.2,
    'Government Confidence': 98.2,
    'Inflation Expectations': 0.022,
    Unemployment: 0.062,
    'Under-Employment': 0.088,
  },
  {
    period: 'MAY 19',
    'Consumer Confidence': 86.2,
    'Business Confidence': 117.2,
    'Government Confidence': 96.2,
    'Inflation Expectations': 0.022,
    Unemployment: 0.062,
    'Under-Employment': 0.08,
  },
  {
    period: 'JUN 19',
    'Consumer Confidence': 83.2,
    'Business Confidence': 117.2,
    'Government Confidence': 93.2,
    'Inflation Expectations': 0.022,
    Unemployment: 0.062,
    'Under-Employment': 0.088,
  },
  {
    period: 'JUL 19',
    'Consumer Confidence': 80.8,
    'Business Confidence': 117.8,
    'Government Confidence': 90.8,
    'Inflation Expectations': 0.028,
    Unemployment: 0.068,
    'Under-Employment': 0.088,
  },
  {
    period: 'AUG 19',
    'Consumer Confidence': 83,
    'Business Confidence': 117,
    'Government Confidence': 93,
    'Inflation Expectations': 0.02,
    Unemployment: 0.06,
    'Under-Employment': 0.082,
  },
  {
    period: 'SEP 19',
    'Consumer Confidence': 83.6,
    'Business Confidence': 117.6,
    'Government Confidence': 93.6,
    'Inflation Expectations': 0.026,
    Unemployment: 0.066,
    'Under-Employment': 0.088,
  },
  {
    period: 'OCT 19',
    'Consumer Confidence': 84,
    'Business Confidence': 117,
    'Government Confidence': 94,
    'Inflation Expectations': 0.02,
    Unemployment: 0.06,
    'Under-Employment': 0.08,
  },
  {
    period: 'NOV 19',
    'Consumer Confidence': 83.8,
    'Business Confidence': 117.8,
    'Government Confidence': 93.8,
    'Inflation Expectations': 0.028,
    Unemployment: 0.068,
    'Under-Employment': 0.086,
  },
  {
    period: 'DEC 19',
    'Consumer Confidence': 81.6,
    'Business Confidence': 117.6,
    'Government Confidence': 91.6,
    'Inflation Expectations': 0.026,
    Unemployment: 0.066,
    'Under-Employment': 0.086,
  },
  {
    period: 'JAN 20',
    'Consumer Confidence': 81.6,
    'Business Confidence': 117.6,
    'Government Confidence': 91.6,
    'Inflation Expectations': 0.026,
    Unemployment: 0.066,
    'Under-Employment': 0.086,
  },
];

const initialState = {
  data: dataExample,
  isFetching: false,
  error: {},
};

const chart = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_RECOVERY_TREND_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.GET_RECOVERY_TREND_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    case actions.GET_RECOVERY_TREND_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};

export default chart;
