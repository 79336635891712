// @flow
import * as actions from '../../actions';

// const exDat = [
//   { name: 'nt', value: 10.6 },
//   { name: 'nsw', value: 5 },
//   { name: 'qld', value: 2.8 },
//   { name: 'sa', value: 5.8 },
//   { name: 'tas', value: 2 },
//   { name: 'vic', value: 7.8 },
//   { name: 'wa', value: 1.8 },
// ];
const exDat = [
  { name: 'nt', value: 0 },
  { name: 'nsw', value: 0 },
  { name: 'qld', value: 0 },
  { name: 'sa', value: 0 },
  { name: 'tas', value: 0 },
  { name: 'vic', value: 0 },
  { name: 'wa', value: 0 },
];

type State = {
  data: Array<{
    state: string,
    value: number,
  }>,
  isFetching: boolean,
  error: ?{},
};

const initialState = {
  data: exDat,
  isFetching: false,
  error: undefined,
};

const stateChart = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_RECOVERY_STATE_DATA_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.GET_RECOVERY_STATE_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        data: action.payload,
      };
    case actions.GET_RECOVERY_STATE_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};

export default stateChart;
