// @flow
import * as actions from '../../../actions';

type State = {
  isFetching: boolean,
  data: [],
  error: error,
};

const initialState = {
  isFetching: false,
  data: [],
  error: null,
};

const barChart = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_PP_BAR_CHART_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case actions.GET_PP_BAR_CHART_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        periods: action.payload.periods.reverse(),
      };
    case actions.GET_PP_BAR_CHART_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};

export default barChart;
