import { abbreviateNumber } from './';

export function formatValue(value, type) {
  switch (type) {
    case 'currency':
      return `$${abbreviateNumber(value)}`;
    case 'number':
      return abbreviateNumber(value);
    case 'percentage':
      return `${(value * 100).toFixed(1)}%`;
    default:
      return value;
  }
}

export function formatRecoveryValue(value, type) {
  switch (type) {
    case 'currency':
      return `$${abbreviateNumber(value)}`;
    case 'number':
      return abbreviateNumber(value);
    case 'percentage':
      return `${value.toFixed(1)}%`;
    default:
      return value;
  }
}
