// @flow

export const getItemsSortedByName = items => {
  let byId = items.byId;

  return byId === undefined
    ? []
    : Object.keys(byId)
        .sort((a, b) => {
          if (byId[a].name.toLowerCase() < byId[b].name.toLowerCase()) {
            return -1;
          } else if (byId[a].name.toLowerCase() > byId[b].name.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        })
        .map(sortedKey => byId[sortedKey]);
};

export function sortIndustryData(a, b) {
  if ('rankable' in a && a.rankable === false) {
    return 1;
  } else if ('rankable' in b && b.rankable === false) {
    return -1;
  } else {
    return b.value - a.value;
  }
}

export const sortDashboardsData = (a, b, column) => {
  if (column === 'date') {
    return Date.parse(a.dateUpdated) - Date.parse(b.dateUpdated);
  } else if (column === 'name') {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    return aName < bName ? -1 : aName > bName ? 1 : 0;
  } else {
    return;
  }
};
