// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../../helpers';

export const GET_COMPANY_META_REQUEST = 'GET_COMPANY_META_REQUEST';
export const GET_COMPANY_META_SUCCESS = 'GET_COMPANY_META_SUCCESS';
export const GET_COMPANY_META_FAILURE = 'GET_COMPANY_META_FAILURE';
export const RESET_COMPANY_META = 'RESET_COMPANY_META';

const getCompanyMetaRequest = (): Action => {
  return {
    type: GET_COMPANY_META_REQUEST,
  };
};

const getCompanyMetaSuccess = (value): Action => {
  return {
    type: GET_COMPANY_META_SUCCESS,
    payload: value,
  };
};

const getCompanyMetaFailure = (accessInfo): Action => {
  return {
    type: GET_COMPANY_META_FAILURE,
    accessInfo,
  };
};

export const resetCompanyMeta = (): Action => {
  return {
    type: RESET_COMPANY_META,
  };
};

export const getCompanyMeta = (
  dashboardId: number,
  isDemo: boolean
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(getCompanyMetaRequest());

    const token = getAuthToken();
    const organisationId = getState().auth.organisations.currentOrganisation;
    const teamId = getState().auth.teams.currentTeam;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2`;

    if (isDemo) {
      targetUrl += '/risk-demo/meta-data';
    } else {
      targetUrl += `/risk/${dashboardId}/meta-data`;
    }

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getCompanyMetaSuccess(camelizeKeys(response, noCamelize)));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getCompanyMetaFailure(error));
    return error;
  }
};
