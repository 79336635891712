// @flow
import * as actions from '../../actions/riskMonitor';

type State = {
  value: number,
  filters: Array<{ key: number, value: number, text: string }>,
  isFetching: boolean,
  error: {}
};

const filtersExample = [
  { value: 'header', text: 'State' },
  {
    key: 2,
    value: 2,
    text: 'NSW and ACT',
  },
  {
    key: 3,
    value: 3,
    text: 'Queensland',
  },
  {
    key: 4,
    value: 4,
    text: 'South Australia',
  },
  {
    key: 5,
    value: 5,
    text: 'Tasmania',
  },
  {
    key: 6,
    value: 6,
    text: 'Victoria',
  },
  {
    key: 7,
    value: 7,
    text: 'Western Australia',
  },
  { value: 'header', text: 'Age' },
  {
    key: 8,
    value: 8,
    text: 'Under 35',
  },
  {
    key: 9,
    value: 9,
    text: '35 to 64',
  },
  {
    key: 10,
    value: 10,
    text: '65 Plus',
  },
  { value: 'header', text: 'Gender' },
  {
    key: 11,
    value: 11,
    text: 'Male',
  },
  {
    key: 12,
    value: 12,
    text: 'Female',
  },
];

const initialState = {
  value: 0,
  filters: filtersExample,
  isFetching: false,
  error: {}
};

const metricFilter = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.SET_METRIC_FILTER:
      return {
        ...state,
        value: action.payload,
      };
    case actions.LOAD_METRIC_FILTERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        filters: action.payload
      }
    case actions.LOAD_METRIC_FILTERS_FAILURE:
    case actions.SET_METRIC_FILTER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo
      }
    case actions.LOAD_METRIC_FILTERS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    default:
      return state;
  }
};

export default metricFilter;
