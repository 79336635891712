// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';

export const GET_PLAYERS_TREND_DATA_REQUEST = 'GET_PLAYERS_TREND_DATA_REQUEST';
export const GET_PLAYERS_TREND_DATA_SUCCESS = 'GET_PLAYERS_TREND_DATA_SUCCESS';
export const GET_PLAYERS_TREND_DATA_FAILURE = 'GET_PLAYERS_TREND_DATA_FAILURE';

const getPlayersTrendDataRequest = (): Action => {
  return {
    type: GET_PLAYERS_TREND_DATA_REQUEST,
  };
};

const getPlayersTrendDataSuccess = (data): Action => {
  return {
    type: GET_PLAYERS_TREND_DATA_SUCCESS,
    payload: data,
  };
};

const getPlayersTrendDataFailure = (accessInfo): Action => {
  return {
    type: GET_PLAYERS_TREND_DATA_FAILURE,
    accessInfo,
  };
};

const PERIOD_TYPE = 2;
// In months, quarterly data at 5 years 
const DATA_LENGTH = 60;

export const getPlayersTrendData = (dashboardId: number): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(getPlayersTrendDataRequest());

    const token = getAuthToken();
    const currentState = getState();
    const organisationId = currentState.auth.organisations.currentOrganisation;
    const teamId = currentState.auth.teams.currentTeam;
    const {
      metric,
      currentSegment,
    } = currentState.industryDashboards.playersPage;
    const { currentFilter } = currentState.industryDashboards.filters;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/industry/${dashboardId}/charts/players/trend?metric=${metric}&periodType=${PERIOD_TYPE}&filterBreak=${currentFilter}&length=${DATA_LENGTH}&segment=${currentSegment}`;

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getPlayersTrendDataSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getPlayersTrendDataFailure(error));
    return error;
  }
};
