// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../../helpers';

export const GET_RECOVERY_STATE_DATA_REQUEST =
  'GET_RECOVERY_STATE_DATA_REQUEST';
export const GET_RECOVERY_STATE_DATA_SUCCESS =
  'GET_RECOVERY_STATE_DATA_SUCCESS';
export const GET_RECOVERY_STATE_DATA_FAILURE =
  'GET_RECOVERY_STATE_DATA_FAILURE';

const getStateDataRequest = (): Action => {
  return {
    type: GET_RECOVERY_STATE_DATA_REQUEST,
  };
};

const getStateDataSuccess = (data): Action => {
  return {
    type: GET_RECOVERY_STATE_DATA_SUCCESS,
    payload: data,
  };
};

const getStateDataFailure = (accessInfo): Action => {
  return {
    type: GET_RECOVERY_STATE_DATA_FAILURE,
    accessInfo,
  };
};

export const getStateData = (): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(getStateDataRequest());

    const token = getAuthToken();
    const currentState = getState();
    const organisationId = currentState.auth.organisations.currentOrganisation;
    const teamId = currentState.auth.teams.currentTeam;

    const metric = currentState.recovery.currentTab;
    const stateFilter = currentState.recovery.filters.state.currentFilter;
    const genderFilter = currentState.recovery.filters.gender.currentFilter;
    const ageFilter = currentState.recovery.filters.age.currentFilter;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/recovery/charts/state-data?metric=${metric}`;
    // let targetUrl = `http://localhost:3001/reports/v2/recovery/charts/state-data?metric=${metric}`;

    if (stateFilter) {
      targetUrl += `&state=${stateFilter}`;
    }

    if (genderFilter) {
      targetUrl += `&gender=${genderFilter}`;
    }

    if (ageFilter) {
      targetUrl += `&age=${ageFilter}`;
    }

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getStateDataSuccess(camelizeKeys(response, noCamelize)));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getStateDataFailure(error));
    return error;
  }
};
