// @flow
import * as actions from '../../actions/';

type State = {
  order: Array<{}>,
  byId: {},
  isFetching: boolean,
  error: error,
};

const initialState = {
  order: [],
  byId: {},
  isFetching: false,
  error: {},
};

const allDashboards = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_ALL_DASHBOARDS_REQUEST:
    case actions.GET_DASHBOARD_REQUEST:
    case actions.CREATE_DASHBOARD_REQUEST:
    case actions.UPDATE_DASHBOARD_REQUEST:
    case actions.REMOVE_DASHBOARD_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.GET_ALL_DASHBOARDS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined
      };
    case actions.GET_DASHBOARD_SUCCESS:
      return {
         ...state,
        isFetching: false,
        error: undefined,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        }
      };
    case actions.CREATE_DASHBOARD_SUCCESS:
    case actions.UPDATE_DASHBOARD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
          },
        }
      };
    case actions.REMOVE_DASHBOARD_SUCCESS:
      delete state.byId[action.id];
      state.order = state.order.filter(e => e !== action.id);
      return {
        ...state,
        byId: {
          ...state.byId
        },
        order: state.order,
        isFetching: false,
        error: undefined
      };
    case actions.GET_ALL_DASHBOARDS_FAILURE:
    case actions.GET_DASHBOARD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    case actions.CREATE_DASHBOARD_FAILURE:
    case actions.UPDATE_DASHBOARD_FAILURE:
    case actions.REMOVE_DASHBOARD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default allDashboards;
