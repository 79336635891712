// @flow
import { combineReducers } from 'redux';

import { reducer as authReducer } from 'roy-morgan-auth';
import { headerReducer } from 'roy-morgan-header';
import { messages } from './messages';
import dashboards from './dashboards';
import dashboardAccess from './dashboardAccess';
import riskMonitor from './riskMonitor';
import industryDashboards from './industryDashboards';
import dashboardManagement from './dashboardManagement';
import dashboardAccessLevels from './dashboardAccessLevels';
import recovery from './recovery';
import visitVicDashboards from './visitVicDashboards';

const rootReducer = combineReducers<_, { +type: string }>({
  auth: authReducer,
  header: headerReducer,
  messages,
  riskMonitor,
  industryDashboards,
  dashboards,
  dashboardManagement,
  dashboardAccess,
  dashboardAccessLevels,
  recovery,
  visitVicDashboards,
});

export default rootReducer;
