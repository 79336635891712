// @flow
import { combineReducers } from 'redux';

import overview from './overview';
import segmentsPage from './segmentsPage';
import playersPage from './playersPage';
import categoriesPage from './categoriesPage';
import filterList from './filterList';
import filters from './filters';
import segments from './segments';
import players from './players';
import categories from './categories';
import metrics from './metrics';

const industryDashboards = combineReducers<_, { +type: string }>({
  overview,
  segmentsPage,
  playersPage,
  categoriesPage,
  filterList,
  filters,
  segments,
  players,
  categories,
  metrics,
});

export default industryDashboards;
