// @flow
import type { Action } from '../../types';

export const SET_CATEGORIES_METRIC = 'SET_CATEGORIES_METRIC';
export const SET_CATEGORIES_CURRENT_SEGMENT = 'SET_CATEGORIES_CURRENT_SEGMENT';
export const SET_CATEGORIES_CURRENT_CATEGORY = 'SET_CATEGORIES_CURRENT_CATEGORY';

export const setCategoriesMetric = (value): Action => {
  return {
    type: SET_CATEGORIES_METRIC,
    payload: value,
  };
};

export const setCategoriesCurrentSegment = (value): Action => {
  return {
    type: SET_CATEGORIES_CURRENT_SEGMENT,
    payload: value,
  };
};

export const setCategoriesCurrentCategory = (value): Action => {
  return {
    type: SET_CATEGORIES_CURRENT_CATEGORY,
    payload: value,
  };
};
