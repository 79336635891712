// @flow

const months = {
  Jan: 'January',
  Feb: 'February',
  Mar: 'March',
  Apr: 'April',
  May: 'May',
  Jun: 'June',
  Jul: 'July',
  Aug: 'August',
  Sep: 'September',
  Oct: 'October',
  Nov: 'November',
  Dec: 'December',
};

export const dateConverter = (date: string) => {
  const month = date.substring(0, 3);
  const year = date.substring(3);
  return `${months[month]} 20${year}`;
};

export const DATE_FORMAT = 'DD/MM/YYYY hh:mm A';