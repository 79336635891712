// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../../helpers';

export const GET_COMPETITOR_SCORES_LITE_REQUEST = 'GET_COMPETITOR_SCORES_LITE_REQUEST';
export const GET_COMPETITOR_SCORES_LITE_SUCCESS = 'GET_COMPETITOR_SCORES_LITE_SUCCESS';
export const GET_COMPETITOR_SCORES_LITE_FAILURE = 'GET_COMPETITOR_SCORES_LITE_FAILURE';

const getCompetitorScoresLiteRequest = (): Action => {
  return {
    type: GET_COMPETITOR_SCORES_LITE_REQUEST,
  };
};

const getCompetitorScoresLiteSuccess = (data): Action => {
  return {
    type: GET_COMPETITOR_SCORES_LITE_SUCCESS,
    payload: data,
  };
};

const getCompetitorScoresLiteFailure = (accessInfo): Action => {
  return {
    type: GET_COMPETITOR_SCORES_LITE_FAILURE,
    accessInfo
  };
};

export const getCompetitorScoresLite = (dashboardId: number): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(getCompetitorScoresLiteRequest());

    const token = getAuthToken();
    const organisationId = getState().auth.organisations.currentOrganisation;
    const teamId = getState().auth.teams.currentTeam;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/risk/${dashboardId}/charts/competitor-scores-lite`;

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId
    }

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getCompetitorScoresLiteSuccess(camelizeKeys(response, noCamelize)));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getCompetitorScoresLiteFailure(error));
    return error;
  }
};
