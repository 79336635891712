// @flow
import * as React from 'react';
import { NavMenu } from 'roy-morgan-header';
import { Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

import type {
  User,
  Organisation,
  Team,
  Products,
} from 'roy-morgan-auth';

type Props = {
  authUser: ?User,
  organisations: Organisation[],
  teams: Team[],
  currentOrganisation: number,
  currentTeam: number,
  organisationChange: (organisationId: number) => Promise<void>,
  teamChange: (teamId: number) => Promise<void>,
  pathname: string,
  history: {
    push: (p: string) => void,
  },
  products: Products,
  showLogin: boolean,
  switchTo: (
    email: string,
    switchFromEmail: string,
    switchFromToken: string
  ) => Promise<void>,
  logoUrl: string,
  mobileBreakpoint: number,
  userMenuBreakpoint: number,
  isInternalUser: boolean,
};

class ManagedMenu extends React.Component<Props> {
  render() {
    const {
      logoUrl,
      authUser,
      pathname,
      showLogin,
      organisations,
      currentOrganisation,
      teams,
      currentTeam,
      organisationChange,
      teamChange,
      products,
      switchTo,
      history,
      mobileBreakpoint = 1000,
      userMenuBreakpoint = 1200,
      isInternalUser,
    } = this.props;

    return (
      <NavMenu
        logoUrl={logoUrl}
        authUser={authUser}
        publicNav
        pathname={pathname}
        showLogin={showLogin}
        organisations={organisations}
        currentOrganisation={currentOrganisation}
        teams={teams}
        currentTeam={currentTeam}
        organisationChange={organisationChange}
        teamChange={teamChange}
        products={products}
        switchTo={switchTo}
        history={history}
        managedMenuSlug="reports-navigation"
        mobileBreakpoint={mobileBreakpoint}
        userMenuBreakpoint={userMenuBreakpoint}
      >
        {isInternalUser && (
          <Menu.Menu>
            <Menu.Item
              as={NavLink}
              to="/management/dashboards"
              name="manage"
              className="nav-menu__nav-link"
              activeClassName="nav-menu__nav-link--active"
            />
          </Menu.Menu>
        )}
      </NavMenu>
    );
  }
}

export default ManagedMenu;
