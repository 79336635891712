// @flow
import { combineReducers } from 'redux';

import metricFilter from './metricFilter';
import companyMeta from './companyMeta';
import { netScore } from './netScore';
import { industryAverage } from './industryAverage';
import { competitorScores } from './competitorScores';
import { competitorScoresLite } from './competitorScoresLite';
import { netScoreGender } from './netScoreGender';
import { netScoreAge } from './netScoreAge';
import { trustDistrustScores } from './trustDistrustScores';
import { netScoreChart } from './netScoreChart';
import { stateChart } from './stateChart';
import { competitorNetScoreAge } from './competitorNetScoreAge';
import { competitorNetScoreGender } from './competitorNetScoreGender';
import { competitorStateChart } from './competitorStateChart';
import activeTab from './activeTab';

const riskMonitor = combineReducers<_, { +type: string }>({
  metricFilter,
  companyMeta,
  netScore,
  industryAverage,
  competitorScores,
  competitorScoresLite,
  netScoreGender,
  netScoreAge,
  trustDistrustScores,
  netScoreChart,
  stateChart,
  competitorNetScoreAge,
  competitorNetScoreGender,
  competitorStateChart,
  activeTab
});

export default riskMonitor;
