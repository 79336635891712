// @flow
import { combineReducers } from 'redux';

import state from './state';
import age from './age';
import gender from './gender';

const filters = combineReducers<_, { +type: string }>({
  state,
  age,
  gender,
});

export default filters;
