// @flow
import {
  refreshToken as refresh,
  logOut,
  getApiEndpoint,
} from 'roy-morgan-auth';
import type { Dispatch } from '../types';

// Set BASE_URL via environment variable
export const LIVE_API_BASE_URL =
process.env.REACT_APP_RM_ENV && process.env.REACT_APP_RM_ENV === 'prod'
? 'https://liveapi.roymorgan.com'
: process.env.REACT_APP_RM_ENV && process.env.REACT_APP_RM_ENV === 'uat'
? 'https://liveapi.roymorganuat.com'
: 'https://liveapi.roymorgandev.com';

export const BASE_API_URL = getApiEndpoint(process.env.REACT_APP_RM_ENV);

type Props = {
  method: string,
  targetUrl: string,
  token?: string,
  headers?: Object,
  body?: Object,
};

type APIError = Error & {
  content?: Object,
  code?: string,
  msg?: string,
};

/*
 * DO NOT MODIFY THIS METHOD
 *
 * API logic should not live inside this method
 * unless it's going to be used across multiple API calls.
 *
 * Please speak to Sean Gilmore if you have issues with this.
 */
export const callAPI = async ({
  method,
  targetUrl,
  token,
  headers,
  body,
}: Props): Promise<any> => {
  if (headers) {
    headers.refererOverride = window.location.href;
  }
  const response = await fetch(targetUrl, {
    method,
    headers: headers,
    token: token,
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    const error: APIError = Error('Invalid API Response');
    error.msg = errorResponse.errors
      ? errorResponse.errors[0].message
      : errorResponse.statusMessage
      ? errorResponse.statusMessage
      : 'API Error';

    throw error;
  }
  const json = await response.json();

  return json;
};

export const refreshToken = async (dispatch: Dispatch) => {
  if (process.env.NODE_ENV !== 'test') {
    const result = await dispatch(refresh());
    if (!result) {
      dispatch(logOut());
      window.location.href = '/login';
    }
  }
};
