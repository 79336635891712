// @flow
import * as actions from '../../../actions';

const DEFAULT_FILTER = 1;

const exampleData = [
  {
    id: 1,
    name: 'All - Gender',
    order: 1,
  },
  {
    id: 2,
    name: 'Female',
    order: 2,
  },
  {
    id: 3,
    name: 'Male',
    order: 3,
  },
];

type State = {
  isFetching: boolean,
  results: Array<any>,
  error: error,
  currentFilter: number,
};

const initialState = {
  isFetching: false,
  results: exampleData,
  error: undefined,
  currentFilter: DEFAULT_FILTER,
};

const gender = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actions.LOAD_RECOVERY_GENDER_FILTERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.LOAD_RECOVERY_GENDER_FILTERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        results: action.payload,
      };
    case actions.LOAD_RECOVERY_GENDER_FILTERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    case actions.SET_RECOVERY_GENDER_FILTER:
      return {
        ...state,
        currentFilter: action.payload,
      };
    default:
      return state;
  }
};

export default gender;
