//@flow
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import configureStore from './store/configureStore';
import 'roy-morgan-base-theme/dist/semantic.min.css';
import './assets/scss/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import type { Store } from 'redux';

const env = process.env.REACT_APP_RM_ENV || '';

if (process.env.NODE_ENV === 'production') {
  const release = process.env.REACT_APP_RELEASE_VER || '';

  Sentry.init({
    dsn:
      'https://d0653969100a4cde832d1b34effe6d96@o379043.ingest.sentry.io/5206331',
    environment: env,
    release,
  });
}

// $FlowFixMe
const store: Store = configureStore();
const root = document.getElementById('root');

if (root) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    root
  );
} else {
  throw new Error('No root div to mount application.');
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
