// @flow
export * from './actions';
export * from './colours';
export * from './products';
export * from './formValidations';
export * from './getWidth';
export * from './charts';
export * from './numbers';
export * from './sort';
export * from './dashboardTypes';
export * from './createDashboardRoutes';
export * from './universesById';
export * from './formatValue';
export * from './brandColours';
export * from './idToName';
export * from './dates';
export * from './windowResizeHook';
export * from './recoveryTrendChart';
export * from './source';

export const createExcerptIfNotDefined = (
  excerpt: string | '',
  post: string
) => {
  if (excerpt.length === 0) {
    return trunc(post, 500);
  } else {
    return excerpt;
  }
};

export const trunc = (content: string, length: number): string => {
  return content.length > length
    ? content.substr(0, length - 1) + '&hellip;'
    : content;
};

export const noCamelize = (key: string, convert: (k: string) => string) => {
  if (key.toLowerCase() === 'id') {
    return 'id';
  }
  return /(^[A-Z0-9_]+$|^id+$)/.test(key) ? key : convert(key);
};

export const findById = (
  workingArray: ?Array<any>,
  idToFind: number,
  key: string = 'id'
): ?any => {
  if (workingArray) {
    for (let i = 0, len = workingArray.length; i < len; i++) {
      if (workingArray[i][key] === idToFind) {
        return workingArray[i];
      }
    }
  }
  return undefined;
};

export const findBySlug = (
  workingArray: ?Array<any>,
  slugToFind: number | string,
  key: string = 'slug'
): ?any => {
  if (workingArray) {
    for (let i = 0, len = workingArray.length; i < len; i++) {
      if (workingArray[i][key] === slugToFind) {
        return workingArray[i];
      }
    }
  }
  return undefined;
};

/**
 * Check if url is absolute or relative.
 * @param {string} url
 * @returns {boolean}
 */
export const isUrlAbsolute = (url: string) =>
  url.indexOf('://') > 0 || url.indexOf('//') === 0;
