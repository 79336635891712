// @flow
import * as actions from '../../actions/riskMonitor';

type State = {
  industry: string,
  name: string,
  sourceDate: string,
  logo: string,
  isFetching: boolean,
  isSet: boolean,
  error: error,
};

const initialState = {
  industry: '',
  name: '',
  sourceDate: '',
  logo: '',
  isFetching: false,
  isSet: false,
  error: {},
};

const companyMeta = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_COMPANY_META_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.GET_COMPANY_META_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSet: true,
        error: undefined,
        industry: action.payload.industry,
        name: action.payload.name,
        sourceDate: action.payload.sourceDate,
        logo: action.payload.logo,
        lastUpdated: action.payload.lastUpdated,
      };
    case actions.GET_COMPANY_META_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    case actions.RESET_COMPANY_META:
      return initialState;
    default:
      return state;
  }
};

export default companyMeta;
