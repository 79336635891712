// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';

export const UPDATE_DASHBOARD_REQUEST =
  'UPDATE_DASHBOARD_REQUEST';
export const UPDATE_DASHBOARD_SUCCESS =
  'UPDATE_DASHBOARD_SUCCESS';
export const UPDATE_DASHBOARD_FAILURE =
  'UPDATE_DASHBOARD_FAILURE';

const updateDashboardRequest = (): Action => {
  return {
    type: UPDATE_DASHBOARD_REQUEST,
  };
};

const updateDashboardSuccess = (dashboard): Action => {
  return {
    type: UPDATE_DASHBOARD_SUCCESS,
    payload: dashboard,
  };
};

const updateDashboardFailure = (error): Action => {
  return {
    type: UPDATE_DASHBOARD_FAILURE,
    error,
  };
};

export const updateDashboard = (
  dashboardId: number,
  updateData: any,
  dashboardLastUpdateDate: string
): ThunkAction => async (dispatch: Dispatch) => {
  try {
    refreshToken(dispatch);
    dispatch(updateDashboardRequest());

    const token = getAuthToken();

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/management/dashboards/${dashboardId}`;

    const headers = {
      token,
      'Content-Type': 'application/json',
    };

    const body = {
      ...updateData,
      dateUpdated: dashboardLastUpdateDate,
    };

    const response = await callAPI({
      method: 'PUT',
      targetUrl,
      undefined,
      headers,
      body
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(updateDashboardSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(updateDashboardFailure(error));
    return error;
  }
};
