// @flow
import * as actions from '../../actions/';

const currentTab = (state: string = 1, action: Object) => {
  switch (action.type) {
    case actions.SET_RECOVERY_CURRENT_TAB:
      return action.payload;
    default:
      return state;
  }
};

export default currentTab;
