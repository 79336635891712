// @flow
import { combineReducers } from 'redux';

import allDashboards from './allDashboards';
import organisationsWithAccess from './organisationsWithAccess';
import organisationsWithoutAccess from './organisationsWithoutAccess';

const dashboardManagement = combineReducers<_, { +type: string }>({
  allDashboards,
  organisationsWithAccess,
  organisationsWithoutAccess
});

export default dashboardManagement;
