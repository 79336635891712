// @flow
import { combineReducers } from 'redux';
import barChart from './barChart';
import radarChart from './radarChart';

const emotional = combineReducers<_, { +type: string }>({
  barChart,
  radarChart,
});

export default emotional;
