// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../../helpers';

export const GET_OVERVIEW_DATA_REQUEST = 'GET_OVERVIEW_DATA_REQUEST';
export const GET_OVERVIEW_DATA_SUCCESS = 'GET_OVERVIEW_DATA_SUCCESS';
export const GET_OVERVIEW_DATA_FAILURE = 'GET_OVERVIEW_DATA_FAILURE';
export const SET_OVERVIEW_NAME = 'SET_OVERVIEW_NAME';

const getOverviewDataRequest = (): Action => {
  return {
    type: GET_OVERVIEW_DATA_REQUEST,
  };
};

const getOverviewDataSuccess = (data): Action => {
  return {
    type: GET_OVERVIEW_DATA_SUCCESS,
    payload: data,
  };
};

const getOverviewDataFailure = (accessInfo): Action => {
  return {
    type: GET_OVERVIEW_DATA_FAILURE,
    accessInfo,
  };
};

export const setOverviewName = (name): Action => {
  return {
    type: SET_OVERVIEW_NAME,
    payload: name,
  };
};

const PERIOD_TYPE = 2;
const DATA_LENGTH = 12;

export const getOverviewData = (industryId: number): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(getOverviewDataRequest());

    const token = getAuthToken();
    const currentState = getState();
    const organisationId = currentState.auth.organisations.currentOrganisation;
    const teamId = currentState.auth.teams.currentTeam;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/industry/${industryId}/charts/overview?periodType=${PERIOD_TYPE}&length=${DATA_LENGTH}`;

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getOverviewDataSuccess(camelizeKeys(response, noCamelize)));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getOverviewDataFailure(error));
    return error;
  }
};
