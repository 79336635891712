// @flow
import * as actions from '../../../actions';

type State = {
  isFetching: boolean,
  data: [],
  error: error,
};

const initialState = {
  isFetching: false,
  data: [],
  error: null,
};

const radarChart = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_EMOTIONAL_RADAR_CHART_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case actions.GET_EMOTIONAL_RADAR_CHART_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload
      };
    case actions.GET_EMOTIONAL_RADAR_CHART_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};

export default radarChart;
