// @flow
import * as actions from '../actions';

type State = {
  dashboardAccessLevels: Array<{
    id: number,
    name: string,
  }>,
  isFetching: boolean,
  error: error,
};

const initialState = {
  dashboardAccessLevels: [],
  isFetching: false,
  error: {},
};

const dashboardAccessLevels = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_DASHBOARD_ACCESS_LEVELS_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.GET_DASHBOARD_ACCESS_LEVELS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        dashboardAccessLevels: action.payload,
      };
    case actions.GET_DASHBOARD_ACCESS_LEVELS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};

export default dashboardAccessLevels;
