// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';

export const REMOVE_ORGANISATION_ACCESS_REQUEST = 'REMOVE_ORGANISATION_ACCESS_REQUEST';
export const REMOVE_ORGANISATION_ACCESS_SUCCESS = 'REMOVE_ORGANISATION_ACCESS_SUCCESS';
export const REMOVE_ORGANISATION_ACCESS_FAILURE = 'REMOVE_ORGANISATION_ACCESS_FAILURE';

const removeOrganisationAccessRequest = (): Action => {
  return {
    type: REMOVE_ORGANISATION_ACCESS_REQUEST,
  };
};

const removeOrganisationAccessSuccess = (value): Action => {
  return {
    type: REMOVE_ORGANISATION_ACCESS_SUCCESS,
    payload: value,
  };
};

const removeOrganisationAccessFailure = (accessInfo): Action => {
  return {
    type: REMOVE_ORGANISATION_ACCESS_FAILURE,
    accessInfo,
  };
};

export const removeOrganisationAccess = (dashboardId: number, organisation): ThunkAction => async (
  dispatch: Dispatch
) => {
  try {
    refreshToken(dispatch);
    dispatch(removeOrganisationAccessRequest());

    const token = getAuthToken();

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/management/dashboards/${dashboardId}/organisations/${organisation.id}`;

    const headers = {
      token
    };

    const response = await callAPI({
      method: 'DELETE',
      targetUrl,
      undefined,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(removeOrganisationAccessSuccess(organisation));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(removeOrganisationAccessFailure(error));
    return error;
  }
};
