import React from 'react';

export const visitVicSources = {
  Total: (
    <>
      <p>
        <span>Base: </span>All respondents
      </p>
      <p>See instructions tab for population numbers.</p>
    </>
  ),
  'Interstate vs Intrastate': (
    <>
      <p>
        <span>Base: </span>Interstate = all non-Victorian. Intrastate = all
        Victorians.
      </p>
      <p>See instructions tab for population numbers.</p>
    </>
  ),
  Interstate: (
    <>
      <p>
        <span>Base: </span>For each state are all non-residents for that state.
      </p>
      <p>See instructions tab for population numbers.</p>
    </>
  ),
  Intrastate: (
    <>
      <p>
        <span>Base: </span>For each Origin state base is all residents for that
        state.
      </p>
      <p>See instructions tab for population numbers.</p>
    </>
  ),
  'Lifestyle Leaders vs Non Lifestyle Leaders': (
    <>
      <p>
        <span>Base: </span>Lifestyle leaders = Lifestyle leaders in all states.
        Non Lifestyle leaders = Non Lifestyle leaders in all states.
      </p>
      <p>
        See instructions tab for population numbers and a definition of
        “Lifestyle Leaders”
      </p>
    </>
  ),
  'Lifestyle Leaders': (
    <>
      <p>
        <span>Base: </span>Lifestyle leaders in all states.
      </p>
      <p>
        See instructions tab for population numbers and a definition of
        “Lifestyle Leaders”
      </p>
    </>
  ),
  'Traveller Segments': (
    <>
      <p>
        <span>Base: </span>Traveller segments.
      </p>
      <p>
        See instructions tab for population numbers and definitions of each
        segment
      </p>
    </>
  ),
  'Helix Communities': (
    <>
      <p>
        <span>Base: </span>All respondents
      </p>
      <p>
        See instructions tab for population numbers and definitions of each
        Helix community.
      </p>
    </>
  ),
};
