// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';

export const GET_PP_LINE_CHART_DATA_REQUEST = 'GET_PP_LINE_CHART_DATA_REQUEST';
export const GET_PP_LINE_CHART_DATA_SUCCESS = 'GET_PP_LINE_CHART_DATA_SUCCESS';
export const GET_PP_LINE_CHART_DATA_FAILURE = 'GET_PP_LINE_CHART_DATA_FAILURE';

const getPersonalityPerceptionsLineChartDataRequest = (): Action => {
  return {
    type: GET_PP_LINE_CHART_DATA_REQUEST,
  };
};

const getPersonalityPerceptionsLineChartDataSuccess = (data): Action => {
  return {
    type: GET_PP_LINE_CHART_DATA_SUCCESS,
    payload: data,
  };
};

const getPersonalityPerceptionsLineChartDataFailure = (accessInfo): Action => {
  return {
    type: GET_PP_LINE_CHART_DATA_FAILURE,
    accessInfo,
  };
};

export const getPersonalityPerceptionsLineChartData = (
  dashboardId: number
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(getPersonalityPerceptionsLineChartDataRequest());

    const token = getAuthToken();
    const currentState = getState();
    const organisationId = currentState.auth.organisations.currentOrganisation;
    const teamId = currentState.auth.teams.currentTeam;

    const {
      currentFilter,
    } = currentState.visitVicDashboards.filters.personalityDetailedView;

    const {
      currentFilter: statementFilter,
    } = currentState.visitVicDashboards.filters.personalityStatement;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/visit/${dashboardId}/personality-perceptions/charts/detailed?region=2&view=${currentFilter}&statement=${statementFilter}`;

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getPersonalityPerceptionsLineChartDataSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getPersonalityPerceptionsLineChartDataFailure(error));
    return error;
  }
};
