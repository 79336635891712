// @flow
import * as actions from '../../actions';

type State = {
  order: Array<{}>,
  byId: {},
  isFetching: boolean,
  error: error,
};

const initialState = {
  order: [],
  byId: {},
  isFetching: false,
  error: {},
};

const organisationsWithoutAccess = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_ORGANISATIONS_WITHOUT_ACCESS_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.GET_ORGANISATIONS_WITHOUT_ACCESS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined
      };
    case actions.ADD_ORGANISATION_ACCESS_SUCCESS:
      delete state.byId[action.payload.id];
      state.order = state.order.filter(e => e !== action.payload.id);
      return {
        ...state,
        byId: {
          ...state.byId
        },
        order: state.order,
        isFetching: false,
        error: undefined
      };
    case actions.REMOVE_ORGANISATION_ACCESS_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload
          }
        },
        isFetching: false,
        error: undefined
      };
    case actions.GET_ORGANISATIONS_WITHOUT_ACCESS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};

export default organisationsWithoutAccess;
