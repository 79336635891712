// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';

export const GET_EMOTIONAL_BAR_CHART_DATA_REQUEST =
  'GET_EMOTIONAL_BAR_CHART_DATA_REQUEST';
export const GET_EMOTIONAL_BAR_CHART_DATA_SUCCESS =
  'GET_EMOTIONAL_BAR_CHART_DATA_SUCCESS';
export const GET_EMOTIONAL_BAR_CHART_DATA_FAILURE =
  'GET_EMOTIONAL_BAR_CHART_DATA_FAILURE';

const getEmotionalPerceptionsBarChartDataRequest = (): Action => {
  return {
    type: GET_EMOTIONAL_BAR_CHART_DATA_REQUEST,
  };
};

const getEmotionalPerceptionsBarChartDataSuccess = (data): Action => {
  return {
    type: GET_EMOTIONAL_BAR_CHART_DATA_SUCCESS,
    payload: data,
  };
};

const getEmotionalPerceptionsBarChartDataFailure = (accessInfo): Action => {
  return {
    type: GET_EMOTIONAL_BAR_CHART_DATA_FAILURE,
    accessInfo,
  };
};

export const getEmotionalPerceptionsBarChartData = (
  dashboardId: number
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(getEmotionalPerceptionsBarChartDataRequest());

    const token = getAuthToken();
    const currentState = getState();
    const organisationId = currentState.auth.organisations.currentOrganisation;
    const teamId = currentState.auth.teams.currentTeam;

    //TODO: needed here?
    // const { regionFilter } = currentState.visitVic.region;
    const {
      currentFilter,
    } = currentState.visitVicDashboards.filters.emotionalView;

    //* Have removed context from this call
    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/visit/${dashboardId}/emotional-perceptions/charts/summary?region=2&view=${currentFilter}`;
    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getEmotionalPerceptionsBarChartDataSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getEmotionalPerceptionsBarChartDataFailure(error));
    return error;
  }
};
