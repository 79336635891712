// @flow
import * as actions from '../../../actions';

const sampleData = [
  {
    id: 445,
    period: '2015',
    'Lifestyle Leader': 0.78,
    'Non Lifestyle Leader': 0.65,
  },
  {
    id: 457,
    period: '2016',
    'Lifestyle Leader': 0.79,
    'Non Lifestyle Leader': 0.66,
  },
  {
    id: 469,
    period: '2017',
    'Lifestyle Leader': 0.7,
    'Non Lifestyle Leader': 0.67,
  },
  {
    id: 481,
    period: '2018',
    'Lifestyle Leader': 0.79,
    'Non Lifestyle Leader': 0.67,
  },
  {
    id: 493,
    period: '2019',
    'Lifestyle Leader': 0.7,
    'Non Lifestyle Leader': 0.68,
  },
];

type State = {
  isFetching: boolean,
  data: [],
  error: error,
};

const initialState = {
  isFetching: false,
  data: sampleData,
  error: null,
};

const attributeChart = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_IMAGE_LINE_CHART_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case actions.GET_IMAGE_LINE_CHART_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    case actions.GET_IMAGE_LINE_CHART_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};

export default attributeChart;
