// @flow
import * as Sentry from '@sentry/browser';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import type { Action, ThunkAction, Dispatch } from '../../../types';

export const LOAD_PLAYERS_REQUEST = 'LOAD_PLAYERS_REQUEST';
export const LOAD_PLAYERS_SUCCESS = 'LOAD_PLAYERS_SUCCESS';
export const LOAD_PLAYERS_FAILURE = 'LOAD_PLAYERS_FAILURE';

const loadPlayersRequest = (): Action => ({
  type: LOAD_PLAYERS_REQUEST,
});

const loadPlayersSuccess = (players): Action => ({
  type: LOAD_PLAYERS_SUCCESS,
  payload: players,
});

const loadPlayersFailure = (error): Action => ({
  type: LOAD_PLAYERS_FAILURE,
  error,
});

export const loadPlayers = (dashboardId: number): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadPlayersRequest());

    const currentState = getState();
    const token = getAuthToken();

    const headers = {
      token,
      'context-organisation':
        currentState.auth.organisations.currentOrganisation,
      'context-team': currentState.auth.teams.currentTeam,
    };

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/industry/${dashboardId}/players`;

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      headers,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(loadPlayersSuccess(response));
  } catch (error) {
    dispatch(loadPlayersFailure(error));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
