// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';

export const GET_SEGMENTS_CURRENT_DATA_REQUEST =
  'GET_SEGMENTS_CURRENT_DATA_REQUEST';
export const GET_SEGMENTS_CURRENT_DATA_SUCCESS =
  'GET_SEGMENTS_CURRENT_DATA_SUCCESS';
export const GET_SEGMENTS_CURRENT_DATA_FAILURE =
  'GET_SEGMENTS_CURRENT_DATA_FAILURE';

const getSegmentsCurrentDataRequest = (): Action => {
  return {
    type: GET_SEGMENTS_CURRENT_DATA_REQUEST,
  };
};

const getSegmentsCurrentDataSuccess = (data): Action => {
  return {
    type: GET_SEGMENTS_CURRENT_DATA_SUCCESS,
    payload: data,
  };
};

const getSegmentsCurrentDataFailure = (accessInfo): Action => {
  return {
    type: GET_SEGMENTS_CURRENT_DATA_FAILURE,
    accessInfo,
  };
};

// Unique identifier for period type (1: Month, 2: Year Ending, 3: Quarter Ending)
const PERIOD_TYPE = 2;

export const getSegmentsCurrentData = (
  industryId: number
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(getSegmentsCurrentDataRequest());

    const token = getAuthToken();
    const currentState = getState();
    const organisationId = currentState.auth.organisations.currentOrganisation;
    const teamId = currentState.auth.teams.currentTeam;
    const { metric } = currentState.industryDashboards.segmentsPage;
    const { currentFilter } = currentState.industryDashboards.filters;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/industry/${industryId}/charts/segments/current?metric=${metric}&periodType=${PERIOD_TYPE}&filterBreak=${currentFilter}`;

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getSegmentsCurrentDataSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getSegmentsCurrentDataFailure(error));
    return error;
  }
};
