// @flow
import * as Sentry from '@sentry/browser';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { setSegmentsMetric, setPlayersMetric, setCategoriesMetric } from './';
import type { Action, ThunkAction, Dispatch } from '../../../types';

export const LOAD_METRICS_REQUEST = 'LOAD_METRICS_REQUEST';
export const LOAD_METRICS_SUCCESS = 'LOAD_METRICS_SUCCESS';
export const LOAD_METRICS_FAILURE = 'LOAD_METRICS_FAILURE';

const loadMetricsRequest = (): Action => ({
  type: LOAD_METRICS_REQUEST,
});

const loadMetricsSuccess = (metrics): Action => ({
  type: LOAD_METRICS_SUCCESS,
  payload: metrics,
});

const loadMetricsFailure = (error): Action => ({
  type: LOAD_METRICS_FAILURE,
  error,
});

export const loadMetrics = (dashboardId: number): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadMetricsRequest());

    const currentState = getState();
    const token = getAuthToken();

    const headers = {
      token,
      'context-organisation':
        currentState.auth.organisations.currentOrganisation,
      'context-team': currentState.auth.teams.currentTeam,
    };

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/industry/${dashboardId}/metrics`;

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      headers,
    });

    if (response instanceof Error) {
      return response;
    }
    
    const initalMetric = response[0].id;

    dispatch(setSegmentsMetric(initalMetric));
    dispatch(setPlayersMetric(initalMetric));
    dispatch(setCategoriesMetric(initalMetric));
    
    dispatch(loadMetricsSuccess(response));
  } catch (error) {
    dispatch(loadMetricsFailure(error));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
