// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../../helpers';

export const GET_NET_SCORE_AGE_REQUEST = 'GET_NET_SCORE_AGE_REQUEST';
export const GET_NET_SCORE_AGE_SUCCESS = 'GET_NET_SCORE_AGE_SUCCESS';
export const GET_NET_SCORE_AGE_FAILURE = 'GET_NET_SCORE_AGE_FAILURE';

const getNetScoreAgeRequest = (): Action => {
  return {
    type: GET_NET_SCORE_AGE_REQUEST,
  };
};

const getNetScoreAgeSuccess = (data): Action => {
  return {
    type: GET_NET_SCORE_AGE_SUCCESS,
    payload: data,
  };
};

const getNetScoreAgeFailure = (accessInfo): Action => {
  return {
    type: GET_NET_SCORE_AGE_FAILURE,
    accessInfo,
  };
};

export const getNetScoreAge = (
  dashboardId: number,
  isDemo: boolean
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(getNetScoreAgeRequest());

    const token = getAuthToken();
    const organisationId = getState().auth.organisations.currentOrganisation;
    const teamId = getState().auth.teams.currentTeam;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/`;

    if (isDemo) {
      targetUrl += '/risk-demo/charts/net-score-age';
    } else {
      targetUrl += `risk/${dashboardId}/charts/net-score-age`;
    }

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getNetScoreAgeSuccess(camelizeKeys(response, noCamelize)));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getNetScoreAgeFailure(error));
    return error;
  }
};
