import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { getAuthToken } from 'roy-morgan-auth';

import Footer from 'roy-morgan-footer';
import Header from './containers/Header';
import { LoadingIndicator } from 'roy-morgan-auth/lib/components';

const UnauthenticatedApp = lazy(() => import('./UnAuthenticatedApp'));
const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));

function isUserLoggedIn(token) {
  if (token && token !== '0' && token !== 0) {
    return true;
  }
  return false;
}

function AuthenticatedSwitch() {
  const token = getAuthToken();
  const loggedIn = isUserLoggedIn(token);

  // Required in order to move between Authenticated and UnAuthenticated app
  useReactRouter();

  return (
    <>
      <Route
        path="/"
        render={({ ...rest }) => <Header {...rest} loggedIn={loggedIn} />}
      />
      {loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </>
  );
}

function FooterSwitch() {
  let location = useLocation();
  const token = getAuthToken();
  const loggedIn = isUserLoggedIn(token);

  // Don't show the footer for logged in users on visit vic page
  return !(loggedIn && location.pathname.toLowerCase().includes('visitvic')) ? (
    <>
      <Footer />
    </>
  ) : (
    <></>
  );
}
function App() {
  return (
    <>
      <Router>
        <Suspense fallback={<LoadingIndicator />}>
          <AuthenticatedSwitch />
          <FooterSwitch />
        </Suspense>
      </Router>
    </>
  );
}

export default App;
