// @flow
import * as actions from '../../actions/riskMonitor';

type State = {
  data: {
    under35: number,
    '35to64': number,
    '65plus': number,
  },
  isFetching: boolean,
  error: error,
};

const initialState = {
  data: [],
  isFetching: false,
  error: undefined,
};

export const competitorNetScoreAge = (
  state: State = initialState,
  action: Object
) => {
  switch (action.type) {
    case actions.GET_COMPETITOR_NET_SCORE_AGE_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.GET_COMPETITOR_NET_SCORE_AGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        data: action.payload,
      };
    case actions.GET_COMPETITOR_NET_SCORE_AGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};
