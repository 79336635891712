// @flow
import * as actions from '../../actions/riskMonitor';

const activeTab = (state: string = '', action: Object) => {
  switch (action.type) {
    case actions.SET_ACTIVE_TAB:
      return action.payload;
    default:
      return state;
  }
};

export default activeTab;
