// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';

export const ADD_ORGANISATION_ACCESS_REQUEST = 'ADD_ORGANISATION_ACCESS_REQUEST';
export const ADD_ORGANISATION_ACCESS_SUCCESS = 'ADD_ORGANISATION_ACCESS_SUCCESS';
export const ADD_ORGANISATION_ACCESS_FAILURE = 'ADD_ORGANISATION_ACCESS_FAILURE';

const addOrganisationAccessRequest = (): Action => {
  return {
    type: ADD_ORGANISATION_ACCESS_REQUEST,
  };
};

const addOrganisationAccessSuccess = (value): Action => {
  return {
    type: ADD_ORGANISATION_ACCESS_SUCCESS,
    payload: value,
  };
};

const addOrganisationAccessFailure = (accessInfo): Action => {
  return {
    type: ADD_ORGANISATION_ACCESS_FAILURE,
    accessInfo,
  };
};

export const addOrganisationAccess = (dashboardId: number, organisationId: number): ThunkAction => async (
  dispatch: Dispatch
) => {
  try {
    refreshToken(dispatch);
    dispatch(addOrganisationAccessRequest());

    const token = getAuthToken();

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/management/dashboards/${dashboardId}/organisations`;

    const headers = {
      token,
      'Content-Type': 'application/json'
    };

    const body = {
      organisationId
    };

    const response = await callAPI({
      method: 'POST',
      targetUrl,
      undefined,
      headers,
      body
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(addOrganisationAccessSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(addOrganisationAccessFailure(error));
    return error;
  }
};
