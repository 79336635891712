// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { normaliseArrayOfObjects } from '../../helpers'

export const GET_ALL_DASHBOARDS_REQUEST = 'GET_ALL_DASHBOARDS_REQUEST';
export const GET_ALL_DASHBOARDS_SUCCESS = 'GET_ALL_DASHBOARDS_SUCCESS';
export const GET_ALL_DASHBOARDS_FAILURE = 'GET_ALL_DASHBOARDS_FAILURE';

const getAllDashboardsRequest = (): Action => {
  return {
    type: GET_ALL_DASHBOARDS_REQUEST,
  };
};

const getAllDashboardsSuccess = (value): Action => {
  return {
    type: GET_ALL_DASHBOARDS_SUCCESS,
    payload: value,
  };
};

const getAllDashboardsFailure = (accessInfo): Action => {
  return {
    type: GET_ALL_DASHBOARDS_FAILURE,
    accessInfo,
  };
};

export const getAllDashboards = (): ThunkAction => async (
  dispatch: Dispatch
) => {
  try {
    refreshToken(dispatch);
    dispatch(getAllDashboardsRequest());

    const token = getAuthToken();

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/management/dashboards`;

    const headers = {
      token
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getAllDashboardsSuccess(normaliseArrayOfObjects(response)));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getAllDashboardsFailure(error));
    return error;
  }
};
