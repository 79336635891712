// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { normaliseArrayOfObjects } from '../../helpers'

export const GET_ORGANISATIONS_WITHOUT_ACCESS_REQUEST = 'GET_ORGANISATIONS_WITHOUT_ACCESS_REQUEST';
export const GET_ORGANISATIONS_WITHOUT_ACCESS_SUCCESS = 'GET_ORGANISATIONS_WITHOUT_ACCESS_SUCCESS';
export const GET_ORGANISATIONS_WITHOUT_ACCESS_FAILURE = 'GET_ORGANISATIONS_WITHOUT_ACCESS_FAILURE';

const getOrganisationsWithoutAccessRequest = (): Action => {
  return {
    type: GET_ORGANISATIONS_WITHOUT_ACCESS_REQUEST,
  };
};

const getOrganisationsWithoutAccessSuccess = (value): Action => {
  return {
    type: GET_ORGANISATIONS_WITHOUT_ACCESS_SUCCESS,
    payload: value,
  };
};

const getOrganisationsWithoutAccessFailure = (accessInfo): Action => {
  return {
    type: GET_ORGANISATIONS_WITHOUT_ACCESS_FAILURE,
    accessInfo,
  };
};

export const getOrganisationsWithoutAccess = (dashboardId: number): ThunkAction => async (
  dispatch: Dispatch
) => {
  try {
    refreshToken(dispatch);
    dispatch(getOrganisationsWithoutAccessRequest());

    const token = getAuthToken();

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/management/dashboards/${dashboardId}/organisations-without-access`;

    const headers = {
      token
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    let organisations = normaliseArrayOfObjects(response);

    dispatch(getOrganisationsWithoutAccessSuccess(organisations));

    return organisations;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getOrganisationsWithoutAccessFailure(error));
    return error;
  }
};
