// @flow
import * as actions from '../../actions';

type State = {
  data: {
    under35: number,
    '35to64': number,
    '65plus': number,
  },
  isFetching: boolean,
  error: ?{},
};

const initialState = {
  data: {
    under35: 0,
    '35to64': 0,
    '65plus': 0,
  },
  isFetching: false,
  error: null,
  // error: 'Sample size too small',
};

const ageData = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_RECOVERY_AGE_DATA_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.GET_RECOVERY_AGE_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        data: action.payload,
      };
    case actions.GET_RECOVERY_AGE_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};

export default ageData;
