// @flow
import * as actions from '../../../actions';

const DEFAULT_FILTER = 1;

type State = {
  isFetching: boolean,
  results: Array<any>,
  error: error,
  currentFilter: number,
};

const initialState = {
  isFetching: false,
  results: [],
  error: undefined,
  currentFilter: DEFAULT_FILTER,
};

const personalityDetailedView = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actions.LOAD_PP_DETAILED_VIEW_FILTERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case actions.LOAD_PP_DETAILED_VIEW_FILTERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        results: action.payload,
      };
    case actions.LOAD_PP_DETAILED_VIEW_FILTERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    case actions.SET_PP_VIEW_FILTER:
      return {
        ...state,
        currentFilter: action.payload,
      };
    default:
      return state;
  }
};

export default personalityDetailedView;
