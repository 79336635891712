// @flow
import { combineReducers } from 'redux';
import barChart from './barChart';
import radarChart from './radarChart';
import attributeChart from './attributeChart';

const image = combineReducers<_, { +type: string }>({
  barChart,
  radarChart,
  attributeChart,
});

export default image;
