// @flow
import * as actions from '../../../actions';

type State = {
  isFetching: boolean,
  data: [],
  error: error,
};

const initialState = {
  isFetching: false,
  data: [],
  error: null,
};

const radarChart = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_IMAGE_RADAR_CHART_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case actions.GET_IMAGE_RADAR_CHART_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        // data: action.payload.data.sort((a,b) => (a.statementId > b.statementId) ? 1 : ((b.statementId > a.statementId) ? -1 : 0)),
      };
    case actions.GET_IMAGE_RADAR_CHART_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};

export default radarChart;
