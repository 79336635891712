//@flow

export function numberOrPercentage(key) {
  switch (key) {
    case 'Consumer Confidence':
    case 'Business Confidence':
    case 'Government Confidence':
      return 'number';
    case 'Inflation Expectations':
    case 'Unemployment':
    case 'Under-Employment':
      return 'percentage';
    default:
      return 'number';
  }
}

export const chartColors = {
  'Consumer Confidence': '#004b87',
  'Business Confidence': '#0f849e',
  'Government Confidence': '#00aec7',
  'Inflation Expectations': '#505050',
  Unemployment: '#a5a5a5',
  'Under-Employment': '#bbc4d1',
};

export const numberMetrics = [
  'Consumer Confidence',
  'Business Confidence',
  'Government Confidence',
];

export const percentageMetrics = [
  'Inflation Expectations',
  'Unemployment',
  'Under-Employment',
];

export function maxData(data, relevantData = []) {
  const maxDat = data
    .map(period => {
      const values = Object.entries(period).map(entry => {
        let key = entry[0];
        let value = entry[1];
        if (relevantData.includes(key) && key !== 'period') {
          return value;
        }
        return undefined;
      });
      return values.filter(val => val !== undefined);
    })
    .reduce((acc, now) => [...acc, ...now]);

  return Math.max(...maxDat);
}