// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';

export const GET_RECOVERY_TREND_DATA_REQUEST =
  'GET_RECOVERY_TREND_DATA_REQUEST';
export const GET_RECOVERY_TREND_DATA_SUCCESS =
  'GET_RECOVERY_TREND_DATA_SUCCESS';
export const GET_RECOVERY_TREND_DATA_FAILURE =
  'GET_RECOVERY_TREND_DATA_FAILURE';

const getRecoveryTrendDataRequest = (): Action => {
  return {
    type: GET_RECOVERY_TREND_DATA_REQUEST,
  };
};

const getRecoveryTrendDataSuccess = (value): Action => {
  return {
    type: GET_RECOVERY_TREND_DATA_SUCCESS,
    payload: value,
  };
};

const getRecoveryTrendDataFailure = (accessInfo): Action => {
  return {
    type: GET_RECOVERY_TREND_DATA_FAILURE,
    accessInfo,
  };
};

export const getRecoveryTrendData = (): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(getRecoveryTrendDataRequest());

    const token = getAuthToken();
    const currentState = getState();
    const organisationId = currentState.auth.organisations.currentOrganisation;
    const teamId = currentState.auth.teams.currentTeam;

    const stateFilter = currentState.recovery.filters.state.currentFilter;
    //const genderFilter = currentState.recovery.filters.gender.currentFilter;
    //const ageFilter = currentState.recovery.filters.age.currentFilter;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/recovery/charts/trend-chart?state=${stateFilter}`;


    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers,
    });
    
    if (response instanceof Error) {
      throw response;
    }

    dispatch(getRecoveryTrendDataSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getRecoveryTrendDataFailure(error));
    return error;
  }
};
