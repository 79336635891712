// @flow
import type { Action } from '../../types';

export const SET_PLAYERS_METRIC = 'SET_PLAYERS_METRIC';
export const SET_PLAYERS_CURRENT_SEGMENT = 'SET_PLAYERS_CURRENT_SEGMENT';

export const setPlayersMetric = (value): Action => {
  return {
    type: SET_PLAYERS_METRIC,
    payload: value,
  };
};

export const setPlayersCurrentSegment = (value): Action => {
  return {
    type: SET_PLAYERS_CURRENT_SEGMENT,
    payload: value,
  };
};
