// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';

export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';

const getDashboardRequest = (): Action => {
  return {
    type: GET_DASHBOARD_REQUEST,
  };
};

const getDashboardSuccess = (value): Action => {
  return {
    type: GET_DASHBOARD_SUCCESS,
    payload: value,
  };
};

const getDashboardFailure = (accessInfo): Action => {
  return {
    type: GET_DASHBOARD_FAILURE,
    accessInfo,
  };
};

export const getDashboard = (dashboardId: number): ThunkAction => async (
  dispatch: Dispatch
) => {
  try {
    refreshToken(dispatch);
    dispatch(getDashboardRequest());

    const token = getAuthToken();

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/management/dashboards/${dashboardId}`;

    const headers = {
      token
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getDashboardSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getDashboardFailure(error));
    return error;
  }
};
