// @flow
import * as Sentry from '@sentry/browser';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';

import type { Action, ThunkAction, Dispatch } from '../../../types';

export const LOAD_REGION_FILTERS_REQUEST = 'LOAD_REGION_FILTERS_REQUEST';
export const LOAD_REGION_FILTERS_SUCCESS = 'LOAD_REGION_FILTERS_SUCCESS';
export const LOAD_REGION_FILTERS_FAILURE = 'LOAD_REGION_FILTERS_FAILURE';
export const SET_REGION_FILTER = 'SET_REGION_FILTER';

const loadRegionFiltersRequest = (): Action => ({
  type: LOAD_REGION_FILTERS_REQUEST,
});

const loadRegionFiltersSuccess = (filters): Action => ({
  type: LOAD_REGION_FILTERS_SUCCESS,
  payload: filters,
});

const loadRegionFiltersFailure = (error): Action => ({
  type: LOAD_REGION_FILTERS_FAILURE,
  error,
});

export const setRegionFilter = (filter: number): Action => ({
  type: SET_REGION_FILTER,
  payload: filter,
});

export const loadRegionFilters = (dashboardId: number): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(loadRegionFiltersRequest());

    const currentState = getState();
    const token = getAuthToken();

    const headers = {
      token,
      'context-organisation':
        currentState.auth.organisations.currentOrganisation,
      'context-team': currentState.auth.teams.currentTeam,
    };

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/visit/${dashboardId}/image-perceptions/regions`;

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      headers,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(loadRegionFiltersSuccess(response));
  } catch (error) {
    dispatch(loadRegionFiltersFailure(error));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
