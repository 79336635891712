// @flow
import * as Sentry from '@sentry/browser';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';

import type { Action, ThunkAction, Dispatch } from '../../../types';

export const LOAD_PP_DETAILED_VIEW_FILTERS_REQUEST =
  'LOAD_PP_DETAILED_VIEW_FILTERS_REQUEST';
export const LOAD_PP_DETAILED_VIEW_FILTERS_SUCCESS =
  'LOAD_PP_DETAILED_VIEW_FILTERS_SUCCESS';
export const LOAD_PP_DETAILED_VIEW_FILTERS_FAILURE =
  'LOAD_PP_DETAILED_VIEW_FILTERS_FAILURE';
export const SET_PP_DETAILED_VIEW_FILTER = 'SET_PP_DETAILED_VIEW_FILTER';

const loadPersonalityPerceptionsDetailedViewFiltersRequest = (): Action => ({
  type: LOAD_PP_DETAILED_VIEW_FILTERS_REQUEST,
});

const loadPersonalityPerceptionsDetailedViewFiltersSuccess = (
  filters
): Action => ({
  type: LOAD_PP_DETAILED_VIEW_FILTERS_SUCCESS,
  payload: filters,
});

const loadPersonalityPerceptionsDetailedViewFiltersFailure = (
  error
): Action => ({
  type: LOAD_PP_DETAILED_VIEW_FILTERS_FAILURE,
  error,
});

export const setPersonalityPerceptionsDetailedViewFilter = (
  filter: number
): Action => ({
  type: SET_PP_DETAILED_VIEW_FILTER,
  payload: filter,
});

export const loadPersonalityPerceptionsDetailedViewFilters = (
  dashboardId: number
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadPersonalityPerceptionsDetailedViewFiltersRequest());

    const currentState = getState();
    const token = getAuthToken();

    const headers = {
      token,
      'context-organisation':
        currentState.auth.organisations.currentOrganisation,
      'context-team': currentState.auth.teams.currentTeam,
    };

    //TODO: Update endpoint from tab query
    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/visit/${dashboardId}/personality-perceptions/views/detailed`;

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      headers,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(loadPersonalityPerceptionsDetailedViewFiltersSuccess(response));
  } catch (error) {
    dispatch(loadPersonalityPerceptionsDetailedViewFiltersFailure(error));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
