// @flow
import * as actions from '../actions/';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

type State = {
  dashboards: Array<{}>, //TODO: Add dashboard type
  isFetching: boolean,
  error: error,
};

const initialState = {
  dashboards: [],
  isFetching: false,
  error: {},
};

const dashboards = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_DASHBOARD_LIST_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.GET_DASHBOARD_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        dashboards: action.payload,
      };
    case actions.GET_DASHBOARD_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
      case LOG_OUT_SUCCESS:
        return initialState;
    default:
      return state;
  }
};

export default dashboards;
