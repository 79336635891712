// @flow
import type { Action } from '../../../types';

export const SET_METRIC_FILTER = 'SET_METRIC_FILTER';
export const SET_METRIC_FILTER_FAILURE = 'SET_METRIC_FILTER_FAILURE';

export const setMetricFilter = (metric: string): Action => ({
  type: SET_METRIC_FILTER,
  payload: metric,
});
