// @flow
import * as Sentry from '@sentry/browser';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';

import type { Action, ThunkAction, Dispatch } from '../../../types';

export const LOAD_SEGMENTS_REQUEST = 'LOAD_SEGMENTS_REQUEST';
export const LOAD_SEGMENTS_SUCCESS = 'LOAD_SEGMENTS_SUCCESS';
export const LOAD_SEGMENTS_FAILURE = 'LOAD_SEGMENTS_FAILURE';

const loadSegmentsRequest = (): Action => ({
  type: LOAD_SEGMENTS_REQUEST,
});

const loadSegmentsSuccess = (segments): Action => ({
  type: LOAD_SEGMENTS_SUCCESS,
  payload: segments,
});

const loadSegmentsFailure = (error): Action => ({
  type: LOAD_SEGMENTS_FAILURE,
  error,
});

//chart type to return segments for (2=OVERVIEW,4=SEGMENTS,5=PLAYERS,6=CATEGORIES)
export const loadSegments = (
  industryId: number,
  chartType: number
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(loadSegmentsRequest());

    const currentState = getState();
    const token = getAuthToken();

    const headers = {
      token,
      'context-organisation':
        currentState.auth.organisations.currentOrganisation,
      'context-team': currentState.auth.teams.currentTeam,
    };

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/industry/${industryId}/segments?chart=${chartType}`;

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      headers,
    });

    if (response instanceof Error) {
      return response;
    }

    dispatch(loadSegmentsSuccess(response));
  } catch (error) {
    dispatch(loadSegmentsFailure(error));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
