// @flow
import { combineReducers } from 'redux';

import companyMeta from './companyMeta';
import filters from './filters';
import image from './image';
import emotional from './emotional';
import personality from './personality';

const recovery = combineReducers<_, { +type: string }>({
  companyMeta,
  filters,
  image,
  emotional,
  personality,
});

export default recovery;
