// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';

export const GET_CATEGORIES_TREND_DATA_REQUEST =
  'GET_CATEGORIES_TREND_DATA_REQUEST';
export const GET_CATEGORIES_TREND_DATA_SUCCESS =
  'GET_CATEGORIES_TREND_DATA_SUCCESS';
export const GET_CATEGORIES_TREND_DATA_FAILURE =
  'GET_CATEGORIES_TREND_DATA_FAILURE';

const getCategoriesTrendDataRequest = (): Action => {
  return {
    type: GET_CATEGORIES_TREND_DATA_REQUEST,
  };
};

const getCategoriesTrendDataSuccess = (data): Action => {
  return {
    type: GET_CATEGORIES_TREND_DATA_SUCCESS,
    payload: data,
  };
};

const getCategoriesTrendDataFailure = (accessInfo): Action => {
  return {
    type: GET_CATEGORIES_TREND_DATA_FAILURE,
    accessInfo,
  };
};

const PERIOD_TYPE = 2;
// In months, quarterly data at 5 years
const DATA_LENGTH = 60;

export const getCategoriesTrendData = (
  dashboardId: number
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(getCategoriesTrendDataRequest());

    const token = getAuthToken();
    const currentState = getState();
    const organisationId = currentState.auth.organisations.currentOrganisation;
    const teamId = currentState.auth.teams.currentTeam;
    const {
      metric,
      currentSegment,
      currentCategory,
    } = currentState.industryDashboards.categoriesPage;
    const { currentFilter } = currentState.industryDashboards.filters;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/industry/${dashboardId}/charts/categories/trend?metric=${metric}&periodType=${PERIOD_TYPE}&filterBreak=${currentFilter}&segment=${currentSegment}&category=${currentCategory}&length=${DATA_LENGTH}`;

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getCategoriesTrendDataSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getCategoriesTrendDataFailure(error));
    return error;
  }
};
