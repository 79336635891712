// @flow
import * as actions from '../../actions';

type State = {
  data: {
    male: number,
    female: number,
  },
  isFetching: boolean,
  error: ?{},
};

const initialState = {
  data: {
    male: 0,
    female: 0,
  },
  isFetching: false,
  error: undefined,
};

const genderData = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_RECOVERY_GENDER_DATA_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.GET_RECOVERY_GENDER_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        data: action.payload,
      };
    case actions.GET_RECOVERY_GENDER_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};

export default genderData;
