// @flow
import * as actions from '../../../actions';

const DEFAULT_FILTER = null;

type State = {
  isFetching: boolean,
  results: Array<any>,
  error: error,
  currentFilter: number,
};

const initialState = {
  isFetching: false,
  results: [],
  error: undefined,
  currentFilter: DEFAULT_FILTER,
};

const personalityStatement = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actions.LOAD_PP_STATEMENT_FILTERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case actions.LOAD_PP_STATEMENT_FILTERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        results: action.payload,
      };
    case actions.LOAD_PP_STATEMENT_FILTERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case actions.SET_PP_STATEMENT_FILTER:
      return {
        ...state,
        currentFilter: action.payload,
      };
    default:
      return state;
  }
};

export default personalityStatement;
