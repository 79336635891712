// @flow
import * as actions from '../../actions/industryDashboards';
import type {
  OverviewTotalMarket,
  OverviewDataType,
} from '../../types/industry';

type State = {
  name: string,
  totalMarket: OverviewTotalMarket,
  segments: OverviewDataType,
  players: OverviewDataType,
  isFetching: boolean,
  error: error,
};

const initialState = {
  name: 'Fresh Food',
  totalMarket: [],
  segments: [],
  players: [],
  isFetching: false,
  error: undefined,
};

const overview = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_OVERVIEW_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actions.GET_OVERVIEW_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        period: action.payload.period,
        totalMarket: action.payload.totalMarket,
        segments: action.payload.segments,
        players: action.payload.players,
      };
    case actions.GET_OVERVIEW_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    case actions.SET_OVERVIEW_NAME:
      return {
        ...state,
        name: action.payload,
      };
    default:
      return state;
  }
};

export default overview;
