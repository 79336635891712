// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../../helpers';

export const GET_INDUSTRY_AVERAGE_REQUEST = 'GET_INDUSTRY_AVERAGE_REQUEST';
export const GET_INDUSTRY_AVERAGE_SUCCESS = 'GET_INDUSTRY_AVERAGE_SUCCESS';
export const GET_INDUSTRY_AVERAGE_FAILURE = 'GET_INDUSTRY_AVERAGE_FAILURE';

const getIndustryAverageRequest = (): Action => {
  return {
    type: GET_INDUSTRY_AVERAGE_REQUEST,
  };
};

const getIndustryAverageSuccess = (value): Action => {
  return {
    type: GET_INDUSTRY_AVERAGE_SUCCESS,
    payload: value,
  };
};

const getIndustryAverageFailure = (accessInfo): Action => {
  return {
    type: GET_INDUSTRY_AVERAGE_FAILURE,
    accessInfo,
  };
};

export const getIndustryAverage = (
  dashboardId: number,
  isDemo: boolean
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(getIndustryAverageRequest());

    const token = getAuthToken();
    const organisationId = getState().auth.organisations.currentOrganisation;
    const teamId = getState().auth.teams.currentTeam;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/`;

    if (isDemo) {
      targetUrl += '/risk-demo/charts/industry-average';
    } else {
      targetUrl += `risk/${dashboardId}/charts/industry-average`;
    }

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getIndustryAverageSuccess(camelizeKeys(response, noCamelize)));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getIndustryAverageFailure(error));
    return error;
  }
};
