// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../types';
import { callAPI, 
  LIVE_API_BASE_URL
 } from '../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';

export const GET_DASHBOARD_ACCESS_LEVELS_REQUEST =
  'GET_DASHBOARD_ACCESS_LEVELS_REQUEST';
export const GET_DASHBOARD_ACCESS_LEVELS_SUCCESS =
  'GET_DASHBOARD_ACCESS_LEVELS_SUCCESS';
export const GET_DASHBOARD_ACCESS_LEVELS_FAILURE =
  'GET_DASHBOARD_ACCESS_LEVELS_FAILURE';

const getDashboardAccessLevelsRequest = (): Action => {
  return {
    type: GET_DASHBOARD_ACCESS_LEVELS_REQUEST,
  };
};

const getDashboardAccessLevelsSuccess = (value): Action => {
  return {
    type: GET_DASHBOARD_ACCESS_LEVELS_SUCCESS,
    payload: value,
  };
};

const getDashboardAccessLevelsFailure = (accessInfo): Action => {
  return {
    type: GET_DASHBOARD_ACCESS_LEVELS_FAILURE,
    accessInfo,
  };
};

export const getDashboardAccessLevels = (
  dashboardId: number
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(getDashboardAccessLevelsRequest());

    const token = getAuthToken();
    const organisationId = getState().auth.organisations.currentOrganisation;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/management/dashboards/${dashboardId}/access-levels`;

    const headers = {
      token,
      'context-organisation': organisationId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(
      getDashboardAccessLevelsSuccess(camelizeKeys(response, noCamelize))
    );

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getDashboardAccessLevelsFailure(error));
    return error;
  }
};
