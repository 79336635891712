// @flow
import * as actions from '../../actions';

type State = {
  data: Array<{
    id: number,
    age_id: number,
    gender_id: number,
    genderocation_id: number,
    confidence_type_id: number,
    week_ending: Date,
    value: number,
  }>,
  isFetching: boolean,
  error: {},
};

const initialState = {
  data: [],
  isFetching: false,
  error: undefined,
};

const trendData = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_RECOVERY_TREND_DATA_REQUEST:
    // debugger;

      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.GET_RECOVERY_TREND_DATA_SUCCESS:
    // debugger;

      return {
        ...state,
        isFetching: false,
        error: undefined,
        data: action.payload,
      };
    case actions.GET_RECOVERY_TREND_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};

export default trendData;
