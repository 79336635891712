// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../../helpers';

export const GET_NET_SCORE_REQUEST = 'GET_NET_SCORE_REQUEST';
export const GET_NET_SCORE_SUCCESS = 'GET_NET_SCORE_SUCCESS';
export const GET_NET_SCORE_FAILURE = 'GET_NET_SCORE_FAILURE';

const getNetScoreRequest = (): Action => {
  return {
    type: GET_NET_SCORE_REQUEST,
  };
};

const getNetScoreSuccess = (value): Action => {
  return {
    type: GET_NET_SCORE_SUCCESS,
    payload: value,
  };
};

const getNetScoreFailure = (accessInfo): Action => {
  return {
    type: GET_NET_SCORE_FAILURE,
    accessInfo,
  };
};

export const getNetScore = (
  dashboardId: number,
  isDemo: boolean
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(getNetScoreRequest());

    const token = getAuthToken();
    const organisationId = getState().auth.organisations.currentOrganisation;
    const teamId = getState().auth.teams.currentTeam;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2`;

    if (isDemo) {
      targetUrl += '/risk-demo/charts/net-score';
    } else {
      targetUrl += `/risk/${dashboardId}/charts/net-score`;
    }

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getNetScoreSuccess(camelizeKeys(response, noCamelize)));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getNetScoreFailure(error));
    return error;
  }
};
