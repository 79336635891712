// @flow
import * as actions from '../../actions/riskMonitor';

import { trustDat } from '../../trustDistrustScores';

type State = {
  data: Array<{
    period: string,
    value: number,
  }>,
  isFetching: boolean,
  error: ?{},
};

const initialState = {
  data: trustDat,
  isFetching: false,
  error: undefined,
};

export const trustDistrustScores = (
  state: State = initialState,
  action: Object
) => {
  switch (action.type) {
    case actions.GET_TRUST_DISTRUST_SCORES_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case actions.GET_TRUST_DISTRUST_SCORES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        data: action.payload,
      };
    case actions.GET_TRUST_DISTRUST_SCORES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    default:
      return state;
  }
};
