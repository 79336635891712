// @flow
import type { Action } from '../../../types';

export const SET_RECOVERY_STATE_FILTER = 'SET_RECOVERY_STATE_FILTER';
export const SET_RECOVERY_AGE_FILTER = 'SET_RECOVERY_AGE_FILTER';
export const SET_RECOVERY_GENDER_FILTER = 'SET_RECOVERY_GENDER_FILTER';

export const setRecoveryStateFilter = (filter: number): Action => ({
  type: SET_RECOVERY_STATE_FILTER,
  payload: filter,
});

export const setRecoveryAgeFilter = (filter: number): Action => ({
  type: SET_RECOVERY_AGE_FILTER,
  payload: filter,
});

export const setRecoveryGenderFilter = (filter: number): Action => ({
  type: SET_RECOVERY_GENDER_FILTER,
  payload: filter,
});
