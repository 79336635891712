// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';

export const UPDATE_ORGANISATION_DASHBOARD_ACCESS_REQUEST =
  'UPDATE_ORGANISATION_DASHBOARD_ACCESS_REQUEST';
export const UPDATE_ORGANISATION_DASHBOARD_ACCESS_SUCCESS =
  'UPDATE_ORGANISATION_DASHBOARD_ACCESS_SUCCESS';
export const UPDATE_ORGANISATION_DASHBOARD_ACCESS_FAILURE =
  'UPDATE_ORGANISATION_DASHBOARD_ACCESS_FAILURE';

const updateOrganisationDashboardAccessRequest = (): Action => {
  return {
    type: UPDATE_ORGANISATION_DASHBOARD_ACCESS_REQUEST,
  };
};

const updateOrganisationDashboardAccessSuccess = (value): Action => {
  return {
    type: UPDATE_ORGANISATION_DASHBOARD_ACCESS_SUCCESS,
    payload: value,
  };
};

const updateOrganisationDashboardAccessFailure = (error): Action => {
  return {
    type: UPDATE_ORGANISATION_DASHBOARD_ACCESS_FAILURE,
    error,
  };
};

export const updateOrganisationDashboardAccess = (
  dashboardId: number,
  organisationId: number,
  dashboardAccessId: number
): ThunkAction => async (dispatch: Dispatch) => {
  try {
    refreshToken(dispatch);
    dispatch(updateOrganisationDashboardAccessRequest());

    const token = getAuthToken();

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/management/dashboards/${dashboardId}/organisations/${organisationId}`;

    const headers = {
      token,
      'Content-Type': 'application/json',
    };

    const body = {
      dashboardAccessId,
    };

    const response = await callAPI({
      method: 'PUT',
      targetUrl,
      undefined,
      headers,
      body,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(updateOrganisationDashboardAccessSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(updateOrganisationDashboardAccessFailure(error));
    return error;
  }
};
