// @flow
//Used in net score trend chart to find the percentage point at which the line goes between positive and negative scores
export function generateLinePecentage(dataArray: Array<number>) {
  const dataMin = Math.min(...dataArray);
  const dataMax = Math.max(...dataArray);
  const range = dataMax - dataMin;
  let percentage = `${(dataMax / range) * 100}%`;
  if (dataMin > 0) {
    percentage = '100%';
  } else if (dataMax < 0) {
    percentage = '0%';
  }
  return percentage;
}
