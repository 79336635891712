// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';

export const REMOVE_DASHBOARD_REQUEST = 'REMOVE_DASHBOARD_REQUEST';
export const REMOVE_DASHBOARD_SUCCESS = 'REMOVE_DASHBOARD_SUCCESS';
export const REMOVE_DASHBOARD_FAILURE = 'REMOVE_DASHBOARD_FAILURE';

const removeDashboardRequest = (): Action => {
  return {
    type: REMOVE_DASHBOARD_REQUEST
  };
};

const removeDashboardSuccess = (dashboardId: number): Action => {
  return {
    type: REMOVE_DASHBOARD_SUCCESS,
    id: dashboardId
  };
};

const removeDashboardFailure = (error): Action => {
  return {
    type: REMOVE_DASHBOARD_FAILURE,
    error
  };
};

export const removeDashboard = (dashboardId: number): ThunkAction => async (
  dispatch: Dispatch
) => {
  try {
    refreshToken(dispatch);
    dispatch(removeDashboardRequest());

    const token = getAuthToken();

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/management/dashboards/${dashboardId}`;

    const headers = {
      token
    };

    const response = await callAPI({
      method: 'DELETE',
      targetUrl,
      undefined,
      headers
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(removeDashboardSuccess(dashboardId));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(removeDashboardFailure(error));
    return error;
  }
};
