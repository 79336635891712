// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI, LIVE_API_BASE_URL } from '../../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../../helpers';
import type { GetState } from '../../types';

export const GET_NET_SCORE_GENDER_REQUEST = 'GET_NET_SCORE_GENDER_REQUEST';
export const GET_NET_SCORE_GENDER_SUCCESS = 'GET_NET_SCORE_GENDER_SUCCESS';
export const GET_NET_SCORE_GENDER_FAILURE = 'GET_NET_SCORE_GENDER_FAILURE';

const getNetScoreGenderRequest = (): Action => {
  return {
    type: GET_NET_SCORE_GENDER_REQUEST,
  };
};

const getNetScoreGenderSuccess = (data): Action => {
  return {
    type: GET_NET_SCORE_GENDER_SUCCESS,
    payload: data,
  };
};

const getNetScoreGenderFailure = (accessInfo): Action => {
  return {
    type: GET_NET_SCORE_GENDER_FAILURE,
    accessInfo,
  };
};

export const getNetScoreGender = (
  dashboardId: number,
  isDemo: boolean
): ThunkAction => async (dispatch: Dispatch, getState: GetState) => {
  try {
    refreshToken(dispatch);
    dispatch(getNetScoreGenderRequest());

    const token = getAuthToken();
    const organisationId = getState().auth.organisations.currentOrganisation;
    const teamId = getState().auth.teams.currentTeam;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/`;

    if (isDemo) {
      targetUrl += '/risk-demo/charts/net-score-gender';
    } else {
      targetUrl += `risk/${dashboardId}/charts/net-score-gender`;
    }

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getNetScoreGenderSuccess(camelizeKeys(response, noCamelize)));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getNetScoreGenderFailure(error));
    return error;
  }
};
