// @flow
import { combineReducers } from 'redux';

import chart from './chart';
import metrics from './metrics';
import ageData from './ageData';
import genderData from './genderData';
import stateData from './stateData';
import filters from './filters';
import currentTab from './currentTab';
import trendData from './trendData';

const recovery = combineReducers<_, { +type: string }>({
  chart,
  metrics,
  ageData,
  genderData,
  stateData,
  filters,
  currentTab,
  trendData
});

export default recovery;
