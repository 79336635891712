// @flow
export function roundNumber(value: number) {
  const roundedNum = Math.floor(value * 10) / 10;
  if (roundedNum % 1 === 0) {
    return roundedNum.toFixed(1);
  } else {
    return roundedNum;
  }
}

export function styleNumber(number: number) {
  if (number > 0) {
    return 'green';
  } else if (number < 0) {
    return 'red';
  } else {
    return '#27251f';
  }
}

export function abbreviateNumber(number) {
  var SI_SYMBOL = ['', 'k', 'M', 'B', 'T', 'P', 'E'];

  // what tier? (determines SI symbol)
  var tier = (Math.log10(number) / 3) | 0;

  // if zero, we don't need a suffix
  //debugger;
  //if (tier === 0) return number.toFixed(1);

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}
