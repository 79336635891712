// @flow

export const DASHBOARD_TYPE_RISK = 1;
export const DASHBOARD_TYPE_INDUSTRY = 2;
export const DASHBOARD_TYPE_VISIT = 3;
export const DASHBOARD_TYPE_CUSTOM = 4;
export const DASHBOARD_TYPE_EMBED = 5;

export const dashboardTypeById = {
  1: 'risk',
  2: 'industry',
  3: 'visitvic',
  4: 'custom',
  5: 'embed',
};
