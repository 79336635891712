// @flow
import * as actions from '../../actions/industryDashboards';
import type { CurrentChart, TrendChart } from '../../types/industry';

type State = {
  metric: number,
  currentSegment: number,
  currentCategory: number,
  isFetchingCurrent: boolean,
  isFetchingTrend: boolean,
  current: CurrentChart,
  trend: TrendChart,
  errorCurrent: error,
  errorTrend: error,
};

const initialState = {
  metric: 1,
  currentSegment: 1,
  currentCategory: 1,
  isFetchingCurrent: false,
  isFetchingTrend: false,
  current: {},
  trend: {},
  errorCurrent: undefined,
  errorTrend: undefined,
};

const categoriesPage = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.SET_CATEGORIES_METRIC:
      return {
        ...state,
        metric: action.payload,
      };
    case actions.SET_CATEGORIES_CURRENT_SEGMENT:
      return {
        ...state,
        currentSegment: action.payload,
      };
    case actions.SET_CATEGORIES_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: action.payload,
      };
    case actions.GET_CATEGORIES_CURRENT_DATA_REQUEST:
      return {
        ...state,
        isFetchingCurrent: true,
        errorCurrent: undefined,
      };
    case actions.GET_CATEGORIES_TREND_DATA_REQUEST:
      return {
        ...state,
        isFetchingTrend: true,
        errorTrend: undefined,
      };
    case actions.GET_CATEGORIES_CURRENT_DATA_SUCCESS:
      return {
        ...state,
        isFetchingCurrent: false,
        current: action.payload,
      };
    case actions.GET_CATEGORIES_TREND_DATA_SUCCESS:
      return {
        ...state,
        isFetchingTrend: false,
        trend: action.payload,
      };
    case actions.GET_CATEGORIES_CURRENT_DATA_FAILURE:
      return {
        ...state,
        isFetchingCurrent: false,
        errorCurrent: action.accessInfo,
        current: {},
      };
    case actions.GET_CATEGORIES_TREND_DATA_FAILURE:
      return {
        ...state,
        isFetchingTrend: false,
        errorTrend: action.accessInfo,
        trend: {},
      };
    default:
      return state;
  }
};

export default categoriesPage;
