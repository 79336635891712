// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../types';
import { callAPI, LIVE_API_BASE_URL } from '../middleware';
import { refreshToken, getAuthToken } from 'roy-morgan-auth';
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';

export const GET_DASHBOARD_LIST_REQUEST = 'GET_DASHBOARD_LIST_REQUEST';
export const GET_DASHBOARD_LIST_SUCCESS = 'GET_DASHBOARD_LIST_SUCCESS';
export const GET_DASHBOARD_LIST_FAILURE = 'GET_DASHBOARD_LIST_FAILURE';

const getDashboardListRequest = (): Action => {
  return {
    type: GET_DASHBOARD_LIST_REQUEST,
  };
};

const getDashboardListSuccess = (value): Action => {
  return {
    type: GET_DASHBOARD_LIST_SUCCESS,
    payload: value,
  };
};

const getDashboardListFailure = (accessInfo): Action => {
  return {
    type: GET_DASHBOARD_LIST_FAILURE,
    accessInfo,
  };
};

export const getDashboardList = (): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(getDashboardListRequest());

    const token = getAuthToken();
    const organisationId = getState().auth.organisations.currentOrganisation;
    const teamId = getState().auth.teams.currentTeam;

    let targetUrl = `${LIVE_API_BASE_URL}/reports/v2/dashboards`;

    const headers = {
      token,
      'context-organisation': organisationId,
      'context-team': teamId,
    };

    const response = await callAPI({
      method: 'GET',
      targetUrl,
      undefined,
      headers,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(getDashboardListSuccess(camelizeKeys(response, noCamelize)));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getDashboardListFailure(error));
    return error;
  }
};
