// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import type { User } from '../../types';
import { getAuthToken, getApiBaseURL } from 'roy-morgan-auth';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

function updateUserRequest() {
  return {
    type: USER_UPDATE_REQUEST,
  };
}

function updateUserSuccess(user: User) {
  return {
    type: USER_UPDATE_SUCCESS,
    payload: user,
  };
}

function updateUserFailure(error: string) {
  return {
    type: USER_UPDATE_FAILURE,
    error: error,
  };
}

export const updateUser = (body: Object): any => async (dispatch) => {
  try {
    refreshToken(dispatch);
    const token = getAuthToken();
    dispatch(updateUserRequest());
    const today = new Date();

    const requestBody = {
      ...body,
      dateUpdated: today.toISOString(),
    };

    const baseUrl = getApiBaseURL('v2', 'management');

    const headers = new Headers({
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      token,
    });

    const response = await callAPI({
      method: 'PUT',
      targetUrl: `${baseUrl}/active-user`,
      token,
      body: requestBody,
      headers,
    });

    dispatch(updateUserSuccess(response));
    return response;
  } catch (error) {
    dispatch(updateUserFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
