// @flow
import * as Sentry from '@sentry/browser';
import type { Action, ThunkAction } from '../../../types';

export const SET_CURRENT_FILTER = 'SET_CURRENT_FILTER';
export const SET_FILTER_FAILURE = 'SET_FILTER_FAILURE';

const setCurrentFilter = (filter: number): Action => ({
  type: SET_CURRENT_FILTER,
  payload: filter,
});

const setFilterFailure = (error): Action => ({
  type: SET_FILTER_FAILURE,
  error,
});

export const setFilter = (filterId: number): any | ThunkAction => async (
  dispatch
): any => {
  try {
    await dispatch(setCurrentFilter(filterId));
  } catch (error) {
    dispatch(setFilterFailure(error));
    if (process.env.NODE_ENV !== 'test') {
      Sentry.captureException(error);
      console.error(error);
    }
    return error;
  }
};
