// @flow
import * as actions from '../actions/';

type State = {
  id: number,
  organisationId: number,
  dashboardAccessLevelId: number,
  dashboardAccessLevel: number,
  isFetching: boolean,
  isSet: boolean,
  error: error,
};

const initialState = {
  id: 0,
  organisationId: 0,
  dashboardAccessLevelId: 0,
  dashboardAccessLevel: 0,
  isFetching: false,
  isSet: false,
  error: {},
};

const dashboardAccess = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case actions.GET_DASHBOARD_ACCESS_LEVEL_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
        isSet: false,
      };
    case actions.GET_DASHBOARD_ACCESS_LEVEL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSet: true,
        error: undefined,
        id: action.payload.id,
        organisationId: action.payload.organisationId,
        dashboardAccessLevelId: action.payload.dashboardAccessLevelId,
        dashboardAccessLevel: action.payload.dashboardAccessLevel,
      };
    case actions.GET_DASHBOARD_ACCESS_LEVEL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.accessInfo,
      };
    case actions.RESET_DASHBOARD_ACCESS:
      return {
        ...state,
        isSet: false,
      };
    default:
      return state;
  }
};

export default dashboardAccess;
